// packages block
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
// common block
import '../style.css'
import { TableHeader } from './tableHeader'
import { AccountDialog } from '../accountForm'
import { AccountDetail } from './accountDetail'
import { OnSuccessDialog } from '../../common/onSuccessDialog'
import { ConfirmationDeleteDialog } from '../../common/confirmationDeleteDialog'
// constants, theme, utils and api block
import { ViewAccountDialog } from '../accountForm/viewAccount'
import AgencyBankServices from '../../../apis/agency/bank'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

type BankData = {
    accountNumber: string
    accountTitle: string
    agencyId: number
    bank: any
    bankId: number

    iban: string
    id: number
    merchantId: number
    swiftCode: string
    type: string
}

export const AccountsDetailContainer = () => {
    const { auth } = useSelector((state: RootState) => state)

    const [isEditBankDialogVisible, setIsEditBankDialogVisible] =
        useState(false)
    const [isViewBankDialogVisible, setIsViewBankDialogVisible] =
        useState(false)
    const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isEditedItem, setIsEditedItem] = useState<boolean | null>(null)
    const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
        useState(false)

    const [bankData, setBankData] = useState<BankData | null>(null)

    const { t } = useTranslation()

    const openEditAccountDialog = () => {
        setIsEditBankDialogVisible(true)
    }

    const openViewBankDialog = () => {
        setIsViewBankDialogVisible(true)
        setIsEditBankDialogVisible(false)
    }

    const closeEditBankDialog = () => {
        setIsEditedItem(true)
        setIsEditBankDialogVisible(false)
        setTimeout(() => {
            setIsEditedItem(null)
        }, 500)
    }

    const closeViewBankDialog = () => {
        setBankFields({
            bankName: bankData?.bank.name ?? '',
            bankSwiftCode: bankData?.swiftCode ?? '',
            bankAddress: bankData?.bank.address ?? '',
            accountNumber: bankData?.accountNumber ?? '',
            accountTitle: bankData?.accountTitle ?? '',
            accountIban: bankData?.iban ?? '',
        })
        setIsViewBankDialogVisible(false)
        setTimeout(() => {
            setIsEditedItem(null)
        }, 500)
    }

    const goHome = () => {
        // window.location.href = '/accounts'
    }

    const successCallback = async () => {
        setBankFields({
            bankName: '',
            bankSwiftCode: '',
            bankAddress: '',
            accountNumber: '',
            accountTitle: '',
            accountIban: '',
        })
        if (auth.user) {
            AgencyBankServices.editBank(
                auth.user.agencyId,
                bankSwiftCode,
                accountNumber,
                accountTitle,
                accountIban,
                bankAddress,
                bankName,
                auth?.token
            )
                .then((response) => {
                    if (response.status) {
                        setBankData((prevState: any) => ({
                            ...prevState,
                            ...response.data,
                            bank: {
                                address:
                                    response.data.bankAddress ??
                                    prevState.bank.address,
                                name:
                                    response.data.bankName ??
                                    prevState.bank.name,
                            },
                        }))
                    }
                })
                .catch((error) => console.error(error))
        }
        closeEditBankDialog()
        setIsEditedItem(null)
        setIsSuccessDialogVisible(true)
    }

    const handleSuccessOkButton = () => {
        setIsSuccessDialogVisible(false)
        closeViewBankDialog()
    }
    const handleDelete = async () => {
        if (auth.user && bankData) {
            AgencyBankServices.deleteBank(bankData.id, auth?.token)
                .then((response) => {
                    if (response.status) {
                        setLoading(false)
                        setBankData(null)
                    }
                })
                .catch((error) => console.error(error))
        }
        setIsConfirmationDialogVisible(false)
        // successCallback()
    }

    const handleCancel = () => {
        setIsConfirmationDialogVisible(false)
    }

    const handleOpenDeleteDialog = () => {
        setIsConfirmationDialogVisible(true)
    }

    useEffect(() => {
        if (auth.user) {
            AgencyBankServices.getBank(auth.user.agencyId, auth?.token)
                .then((response) => {
                    if (response.status) {
                        setBankData(response.data)
                    }
                })
                .catch((error) => console.error(error))
        }
        // eslint-disable-next-line
    }, [auth.user])

    const [
        {
            bankName,
            bankSwiftCode,
            bankAddress,
            accountNumber,
            accountTitle,
            accountIban,
        },
        setBankFields,
    ] = useState({
        bankName: '',
        bankSwiftCode: '',
        bankAddress: '',
        accountNumber: '',
        accountTitle: '',
        accountIban: '',
    })
    useEffect(() => {
        console.log(bankName, bankSwiftCode, bankAddress, accountNumber)
    }, [setBankFields])
    useEffect(() => {
        if (bankData) {
            setBankFields({
                bankName: bankData.bank.name,
                bankSwiftCode: bankData.swiftCode,
                bankAddress: bankData.bank.address,
                accountNumber: bankData.accountNumber,
                accountTitle: bankData.accountTitle,
                accountIban: bankData.iban,
            })
        }
    }, [bankData])
    return (
        <div className="account-management-page">
            <div>
                <TableHeader
                    handleAdd={openEditAccountDialog}
                    handleDelete={handleOpenDeleteDialog}
                />

                {loading ? (
                    <div className={'account-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        {'Fetching Account Data'}
                    </div>
                ) : (
                    <AccountDetail accountData={bankData} />
                )}
            </div>

            {isEditBankDialogVisible && (
                <AccountDialog
                    setBankFields={setBankFields}
                    title={'Edit Bank Details'}
                    handleClose={closeEditBankDialog}
                    open={isEditBankDialogVisible}
                    successCallback={openViewBankDialog}
                    isEditedItem={isEditedItem}
                    showCircularProgressBar={true}
                    setBankData={setBankData}
                    bankName={bankName}
                    bankSwiftCode={bankSwiftCode}
                    bankAddress={bankAddress}
                    accountNumber={accountNumber}
                    accountTitle={accountTitle}
                    accountIban={accountIban}
                />
            )}

            {isViewBankDialogVisible && (
                <ViewAccountDialog
                    bankData={bankData}
                    title={'View Bank Details'}
                    handleClose={closeViewBankDialog}
                    open={isViewBankDialogVisible}
                    successCallback={successCallback}
                    showCircularProgressBar={true}
                    bankName={bankName}
                    bankSwiftCode={bankSwiftCode}
                    bankAddress={bankAddress}
                    accountNumber={accountNumber}
                    accountTitle={accountTitle}
                    accountIban={accountIban}
                />
            )}

            {isSuccessDialogVisible && (
                <OnSuccessDialog
                    handleClose={closeEditBankDialog}
                    open={isSuccessDialogVisible}
                    goHome={handleSuccessOkButton}
                />
            )}

            {isConfirmationDialogVisible && (
                <ConfirmationDeleteDialog
                    open={isConfirmationDialogVisible}
                    close={handleCancel}
                    loading={loading}
                    handleDelete={handleDelete}
                    title={t('text_account')}
                />
            )}
        </div>
    )
}
