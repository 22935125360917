import { BASE_IMG } from '../../../constants'
import { BLACK_EIGHT } from '../../../theme'
import './footer.css'
import { TableRow, TableCell, Typography, Avatar } from '@mui/material'

export const DashboardTransactionRow = ({
    isItemSelected,
    row,
}: // avatarColor,
any) => {
    const payeeFirstName = row['payeeInfo.firstName'] ?? ''
    const payeeLastName = row['payeeInfo.lastName'] ?? ''
    const agentFirstName = row['paymentLink.agent.firstName'] ?? ''
    const agentLastName = row['paymentLink.agent.lastName'] ?? ''
    const paymentLinkId = row['paymentLinkId'] ?? ''

    return (
        <>
            {
                <TableRow
                    hover
                    style={{
                        padding: '3px 0px',
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row?.name}
                    selected={isItemSelected}
                >
                    <TableCell
                        align="center"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Avatar className="table-avatar">
                            {agentFirstName[0]?.toUpperCase() ||
                                '' + ' ' + agentLastName[0]?.toUpperCase() ||
                                ''}
                        </Avatar>

                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Typography
                                variant="h5"
                                style={{
                                    color: BLACK_EIGHT,
                                    fontSize: '0.8vw',
                                }}
                            >
                                {/* {`${row['paymentLink.agent.firstName']} ${row['paymentLink.agent.lastName']}`} */}

                                {row['paymentLink.agent.firstName'] ||
                                row['paymentLink.agent.lastName']
                                    ? `${row['paymentLink.agent.firstName']}` +
                                      ' ' +
                                      `${row['paymentLink.agent.lastName']}`
                                    : 'N/A'}
                            </Typography>
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar className="table-avatar">
                                {row['payeeInfo.firstName'] ||
                                row['payeeInfo.lastName']
                                    ? `${row[
                                          'payeeInfo.firstName'
                                      ][0]?.toUpperCase()}` +
                                      ' ' +
                                      `${row[
                                          'payeeInfo.lastName'
                                      ][0]?.toUpperCase()}`
                                    : 'N/A'}
                            </Avatar>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: BLACK_EIGHT,
                                        fontSize: '0.8vw',
                                    }}
                                >
                                    {payeeFirstName || payeeLastName
                                        ? `${payeeFirstName}` +
                                          ' ' +
                                          `${payeeLastName}`
                                        : 'N/A'}
                                </Typography>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{
                            textTransform: 'capitalize',
                            fontWeight: 500,
                        }}
                    >
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {paymentLinkId ?? 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{
                            textTransform: 'capitalize',
                            fontWeight: 500,
                        }}
                    >
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row['orderId'] ?? 'N/A'}
                        </Typography>
                    </TableCell>

                    <TableCell align="center" style={{ fontWeight: 500 }}>
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row['paymentLink.description'] ?? 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>
            }
        </>
    )
}
