import axios from 'axios'
import {
    CHANGE_AGENCY_USER_PASSWORD_URL,
    UPDATE_AGENCY_PROFILE_PICTURE_URL,
    UPDATE_AGENCY_USER_URL,
    UPDATE_AGENT_PROFILE_PICTURE_URL,
} from '../../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgencyProfileServices = {
    updateProfile: async (
        agencyUserId: number,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber?: string,
        countryCode?: string,
        token?: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const formattedCountryCode = countryCode?.includes('+')
                ? countryCode
                : `+${countryCode}`
            const updateObj = {
                agencyUserId: agencyUserId,
                firstName: firstName.length ? firstName : undefined,
                lastName: lastName.length ? lastName : undefined,
                email: email.length ? email : undefined,
                phoneNumber: phoneNumber?.length ? phoneNumber : undefined,
                countryCode: countryCode?.length
                    ? formattedCountryCode
                    : undefined,
            }
            const response = await axios.put(
                UPDATE_AGENCY_USER_URL,
                updateObj,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Could not update agencyUser',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                data: undefined,
                statusMsg: 'Could not update agencyUser. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    updateProfilePicture: async (formData: FormData) => {
        try {
            const response = await axios.put(
                UPDATE_AGENCY_PROFILE_PICTURE_URL,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error uploading profile picture:', error)
        }
    },

    changePassword: async (
        agencyUserId: number,
        oldPassword: string,
        newPassword: string,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const updateObj = {
                agencyUserId: agencyUserId,
                oldPassword: oldPassword,
                newPassword: newPassword,
            }
            const response = await axios.put(
                CHANGE_AGENCY_USER_PASSWORD_URL,
                updateObj,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Could not update password',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                data: undefined,
                statusMsg: 'Could not update password. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgencyProfileServices
