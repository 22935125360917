// packages block
import { TableRow, TableCell, Typography, Avatar } from '@mui/material'
import { BLACK_EIGHT, LIGHT_GREEN } from '../../../theme'
import { useHistory } from 'react-router'

export const PaymentLinkTransactionRow = ({
    isItemSelected,
    row,
}: // avatarColor,
any) => {
    const history = useHistory()

    const firstName = row?.payeeInfo?.firstName
    const lastName = row?.payeeInfo?.lastName
    const pathname = document.location.pathname

    const handleOnRowClick = () => {
        history.push(`${pathname}/${row?.id}`)
    }

    return (
        <TableRow
            hover
            style={{ padding: '3px 0px', cursor: 'pointer' }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row?.name}
            selected={isItemSelected}
            onClick={() => handleOnRowClick()}
        >
            <TableCell
                align="center"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Avatar className="table-avatar">
                    {row?.payeeInfo?.firstName || row?.payeeInfo?.lastName
                        ? `${
                              row?.payeeInfo?.firstName[0]?.toUpperCase() || ''
                          }` +
                          ' ' +
                          `${row?.payeeInfo?.lastName[0]?.toUpperCase() || ''}`
                        : 'N/A'}
                </Avatar>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h5"
                        style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                    >
                        {firstName || lastName
                            ? `${firstName}` + ' ' + `${lastName}`
                            : 'N/A'}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                align="center"
                style={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    padding: 10,
                }}
            >
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {row?.amount ? `AED ${row?.amount}.00` : 'N/A'}
                </Typography>
            </TableCell>

            <TableCell align="center" style={{ fontWeight: 500, padding: 10 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {row?.amount ? `AED ${row?.amount}.00` : 'N/A'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    align="center"
                    variant="h5"
                    style={{ color: LIGHT_GREEN, fontSize: '0.8vw' }}
                >
                    {row?.status ?? 'Completed'}
                </Typography>
            </TableCell>
        </TableRow>
    )
}
