// packages block
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useEffect, useRef, useState } from 'react'
// components block
import doneIcon from '../../assets/confirm-icon-animation.json'
import paymentIcon from '../../assets/payment-icon-animation.json'
import { paymentMegthodsIconData } from '../../constants'
import { BLACK_EIGHT, WHITE } from '../../theme'
import { FormButton } from '../common/button'
import backButtonIcon from '../../assets/images/back-button-icon.svg'
import './style.css'
import { TextFieldComponent } from '../common/textFeild'
import bgImage from '../../assets/images/external-bg.png'
import applePayIcon from '../../assets/images/apple-pay-icon.png'
import visaMasterIcon from '../../assets/images/visa-master-icon.svg'
import { useHistory, useParams } from 'react-router'
import AgentPaymentLinks from '../../apis/agent/paymentLinks'
import AgentTransactions from '../../apis/agent/transactions'
import Alert from '../common/Alert'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import failedIcon from '../../assets/expire-icon-animation.json'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import paynestCollectLogo from '../../assets/images/paynest-collect-login-logo.png'
import { useMediaQuery } from '@material-ui/core'

type RouteParams = {
    uuid: string
}
type PaymentLinkDetails = {
    invoice: any
    payeeInfo: any
    paymentLink: any
}
interface IEncryptedData {
    txn_details: string
    pg_details: string
    cust_details: string
    bill_details: string
    ship_details: string
    item_details: string
    card_details: string
    other_details: string
    upi_details?: string
    me_id?: string
    paymentUrl?: string
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip placement="top-start" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: BLACK_EIGHT,
        boxShadow: theme.shadows[1],
        border: `1px solid ${BLACK_EIGHT}`,
        fontSize: 14,
    },
}))

export const ExternalPaymentLinkComponent = (): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const isScreenSmall = useMediaQuery(`(max-width:820px)`)
    const history = useHistory()
    const [encryptedData, setEncryptedData] = useState<IEncryptedData | null>(
        null
    )

    const { uuid } = useParams<RouteParams>()
    const formRef = useRef(null)
    const [isExternalPaymentForm, setIsExternalPaymentForm] = useState(false)
    const [isPaymentLinkExpired, setIsPaymentLinkExpired] = useState(false)
    const [
        isExternalPaymentMethodContainer,
        setIsExternalPaymentMethodContainer,
    ] = useState(false)
    const [isSwitchExternalPaymentForm, setIsSwitchExternalPaymentForm] =
        useState(true)
    const [isSuccessMessageContainer, setIsSuccessMessageContainer] =
        useState(false)

    const [paymentLinkDetails, setPaymentLinkDetails] =
        useState<PaymentLinkDetails | null>(null)

    const [
        { firstName, lastName, phone, countryCode, email, message, amount },
        setData,
    ] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        countryCode: '',
        email: '',
        message: '',
        amount: '',
    })
    const [openTooltip, setOpenTooltip] = useState(false)
    const [loading, setLoaading] = useState(true)

    const handleTooltipClose = () => {
        setOpenTooltip(false)
    }

    const handleTooltipOpen = () => {
        if (isScreenSmall) {
            Alert.info('Coming Soon!')
        }

        setOpenTooltip(true)
    }

    const payWithSafeXPay = async () => {
        const transactionRes = await createTransaction('SAFEPAY')
        console.log(transactionRes)
        if (transactionRes?.status) {
            setEncryptedData(transactionRes?.data)
        }
    }

    useEffect(() => {
        if (encryptedData) {
            const submitButton = document.getElementById('submitSafeXPay')
            if (submitButton) {
                submitButton.click()
            }
            // axios
            //     .post(
            //         `${process.env.REACT_APP_API_URL}/api/transactions/receiveSafePayData`,
            //         {
            //             orderId: encryptedData.orderId,
            //             status: 'Successful',
            //         }
            //     )
            //     .then((response) => {
            //         console.log(response.data)
            //         if (response.data.status) {
            //             history.push('/agent/payment-status?status=success')
            //         } else {
            //             history.push('/agent/payment-status?status=failed')
            //         }
            //     })
            //     .catch((error) => {
            //         console.log(error)
            //         history.push('/agent/payment-status?status=failed')
            //     })
        }
    }, [encryptedData])
    const handleContinue = () => {
        setIsSwitchExternalPaymentForm(false)
        setIsExternalPaymentForm(true)
    }

    const handleBack = () => {
        setIsSwitchExternalPaymentForm(true)
        setIsExternalPaymentForm(false)
    }

    const handleComingSoon = () => {
        Alert.success('Coming soon!')
    }

    const handleSwitchPaymentMethod = () => {
        if (
            (paymentLinkDetails?.paymentLink.amountRequired &&
                !amount.length) ||
            (paymentLinkDetails?.paymentLink.payeeInfoRequired &&
                (!firstName.length ||
                    !lastName.length ||
                    !phone.length ||
                    !email.length)) ||
            (paymentLinkDetails?.paymentLink.messageRequired && !message.length)
        ) {
            return Alert.error('Fields missing')
        }
        setIsExternalPaymentForm(false)
        setIsExternalPaymentMethodContainer(true)
    }

    const handlePhoneNumber = (value: string, country: any) => {
        setData((prevState) => ({
            ...prevState,
            phone: value.replace(/\D/g, ''),
            countryCode: country.dialCode,
        }))
    }
    let customMobileNumber: any

    const createTransaction = async (paymentGatewayType: string) => {
        customMobileNumber = phone?.substring(
            countryCode?.length,
            phone?.length
        )

        const transactionRes =
            await AgentTransactions.createTransactionByPaymentLink(
                uuid,
                firstName,
                lastName,
                email,
                customMobileNumber,
                countryCode,
                message,
                paymentGatewayType,
                amount.length
                    ? amount
                    : paymentLinkDetails?.invoice
                    ? paymentLinkDetails?.invoice.amount
                    : paymentLinkDetails?.paymentLink.amount,
                auth?.token
            )
        return transactionRes
    }

    const handlePersonalData = (e: Event) => {
        if (typeof e === 'string' || e instanceof String) return
        const { name, value } = e.target as HTMLInputElement
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    useEffect(() => {
        if (uuid && uuid.length) {
            setLoaading(true)
            AgentPaymentLinks.getAgentPaymentLinkWithUuid(uuid, auth?.token)
                .then((response) => {
                    console.log(response)
                    if (!response?.status) {
                        setIsPaymentLinkExpired(
                            response?.statusMsg ===
                                'Payment link its expired, Please create a new one payment link'
                                ? true
                                : false
                        )
                        setLoaading(false)
                    }
                    if (response.status) {
                        setPaymentLinkDetails(response.data)
                        setIsPaymentLinkExpired(true)
                        setLoaading(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setLoaading(false)
                })
        }
        // axios
        //     .get(
        //         process.env.REACT_APP_API_URL +
        //             '/api/transactions/getEncryptedData'
        //     )
        //     .then((response) => {
        //         console.log(response.data)
        //         if (response.data.status) {
        //             setEncryptedData(response.data.data)
        //         }
        //     })
        //     .catch((error) => console.log(error))
    }, [])
    return (
        <>
            {loading ? (
                <div className={'page-loader'}>
                    <CircularProgress
                        size={20}
                        style={{ color: 'black', marginRight: 10 }}
                    />
                    Loading...
                </div>
            ) : (
                <>
                    {paymentLinkDetails && isPaymentLinkExpired ? (
                        <>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                mt={'25px'}
                                position="relative"
                            >
                                {!isExternalPaymentForm && (
                                    <img
                                        src={bgImage}
                                        style={{
                                            position: 'absolute',
                                            zIndex: '-1',
                                            height: '100vh',
                                            width: '100%',
                                        }}
                                        alt={'background'}
                                    />
                                )}
                                <img
                                    src={paynestCollectLogo}
                                    alt="paynest-collect-logo"
                                    style={{
                                        marginTop: isScreenSmall ? 50 : 70,
                                        marginBottom: isScreenSmall ? 40 : 70,
                                        width: isScreenSmall ? '60px' : '120px',
                                    }}
                                />

                                {isExternalPaymentForm && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        alignSelf="flex-start"
                                        maxWidth="900px"
                                        mx="auto"
                                        width="100%"
                                        mb={isScreenSmall ? '0px' : '30px'}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={handleBack}
                                            style={{
                                                backgroundColor: 'transparent',
                                                maxWidth: '30px',
                                                maxHeight: '30px',
                                                minWidth: '30px',
                                                minHeight: '30px',
                                                borderRadius: '3px',
                                                boxShadow: 'none',
                                            }}
                                        >
                                            <img
                                                src={backButtonIcon}
                                                className="backbut"
                                                style={{
                                                    width: 48,
                                                    cursor: 'pointer',
                                                }}
                                                alt="back-button-icon"
                                            />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            <>
                                {isExternalPaymentForm && (
                                    <Box
                                        className="external-payment-container"
                                        maxWidth="800px"
                                        mx="auto"
                                        bgcolor="white"
                                        sx={{
                                            boxShadow: isScreenSmall
                                                ? '0px'
                                                : '0px 3px 6px #00000029',
                                            borderRadius: isScreenSmall
                                                ? '0px'
                                                : '33px',
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                        >
                                            {isScreenSmall && (
                                                <Typography
                                                    variant="h2"
                                                    style={{
                                                        fontSize: 16,
                                                        marginBottom: '40px',
                                                    }}
                                                    color={BLACK_EIGHT}
                                                >
                                                    Personal Details
                                                </Typography>
                                            )}
                                            {paymentLinkDetails?.paymentLink
                                                .amountRequired ? (
                                                <></>
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    mt="10px"
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    Amount
                                                </Typography>
                                            )}

                                            <Typography
                                                variant="h6"
                                                mt="4px"
                                                mb="30px"
                                                style={{ fontSize: '27px' }}
                                            >
                                                {paymentLinkDetails?.paymentLink
                                                    .amountRequired
                                                    ? ''
                                                    : 'AED ' +
                                                      (paymentLinkDetails
                                                          ?.invoice?.amount ??
                                                          paymentLinkDetails
                                                              ?.paymentLink
                                                              .amount ??
                                                          0)}
                                            </Typography>
                                        </Box>

                                        <Grid container spacing={2}>
                                            {!paymentLinkDetails?.paymentLink
                                                .payeeInfoRequired ? (
                                                <Grid item sm={6} xs={12}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            maxWidth="90px"
                                                            width="100%"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.firstName
                                                                ? `First Name:`
                                                                : ''}
                                                        </Typography>
                                                        &nbsp;&nbsp;
                                                        <Typography
                                                            variant="body1"
                                                            className="prefilled-content"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.firstName
                                                                ? `${paymentLinkDetails?.payeeInfo?.firstName}`
                                                                : ''}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : (
                                                <Grid item sm={6} xs={12}>
                                                    <TextFieldComponent
                                                        required={true}
                                                        value={firstName}
                                                        onChange={(
                                                            e: Event
                                                        ) => {
                                                            handlePersonalData(
                                                                e
                                                            )
                                                        }}
                                                        label={'First Name'}
                                                        name={'firstName'}
                                                    />
                                                </Grid>
                                            )}

                                            {!paymentLinkDetails?.paymentLink
                                                .payeeInfoRequired ? (
                                                <Grid item sm={6} xs={12}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            maxWidth="90px"
                                                            width="100%"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.lastName
                                                                ? `Last Name:`
                                                                : ''}
                                                        </Typography>
                                                        &nbsp;&nbsp;
                                                        <Typography
                                                            variant="body1"
                                                            className="prefilled-content"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.lastName
                                                                ? `${paymentLinkDetails?.payeeInfo?.lastName}`
                                                                : ''}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : (
                                                <Grid item sm={6} xs={12}>
                                                    <TextFieldComponent
                                                        required={true}
                                                        value={lastName}
                                                        onChange={(
                                                            e: Event
                                                        ) => {
                                                            handlePersonalData(
                                                                e
                                                            )
                                                        }}
                                                        label={'Last Name'}
                                                        name={'lastName'}
                                                    />
                                                </Grid>
                                            )}

                                            {!paymentLinkDetails?.paymentLink
                                                .payeeInfoRequired ? (
                                                <Grid item sm={6} xs={12}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            maxWidth="90px"
                                                            width="100%"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.phone
                                                                ? `Phone:`
                                                                : ''}
                                                        </Typography>
                                                        &nbsp;&nbsp;
                                                        <Typography
                                                            variant="body1"
                                                            className="prefilled-content"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.phone
                                                                ? `${paymentLinkDetails?.payeeInfo?.phone}`
                                                                : ''}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : (
                                                <Grid item sm={6} xs={12}>
                                                    {/* <TextFieldComponent
                                                        required={true}
                                                        value={phone}
                                                        onChange={(e: Event) => {
                                                            handlePersonalData(e)
                                                        }}
                                                        label={'Phone Number'}
                                                        name={'phone'}
                                                    /> */}
                                                    <ReactPhoneInput
                                                        containerClass="custom-phone-input"
                                                        inputStyle={{
                                                            width: 'inherit',
                                                            height: '35px',
                                                            borderRadius: 10,
                                                            fontFamily:
                                                                'Montserrat',
                                                        }}
                                                        value={phone}
                                                        onChange={
                                                            handlePhoneNumber
                                                        }
                                                        country={'ae'}
                                                        specialLabel={
                                                            'Phone Number'
                                                        }
                                                    />
                                                </Grid>
                                            )}

                                            {!paymentLinkDetails?.paymentLink
                                                .payeeInfoRequired ? (
                                                <Grid item sm={6} xs={12}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            maxWidth="90px"
                                                            width="100%"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.email
                                                                ? `Email:`
                                                                : ''}
                                                        </Typography>
                                                        &nbsp;&nbsp;
                                                        <Typography
                                                            variant="body1"
                                                            className="prefilled-content"
                                                        >
                                                            {paymentLinkDetails
                                                                ?.payeeInfo
                                                                ?.email
                                                                ? `${paymentLinkDetails?.payeeInfo?.email}`
                                                                : ''}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ) : (
                                                <Grid item sm={6} xs={12}>
                                                    <TextFieldComponent
                                                        required={true}
                                                        value={email}
                                                        onChange={(
                                                            e: Event
                                                        ) => {
                                                            handlePersonalData(
                                                                e
                                                            )
                                                        }}
                                                        label={'Email Address'}
                                                        name={'email'}
                                                    />
                                                </Grid>
                                            )}

                                            <Grid
                                                item
                                                sm={
                                                    paymentLinkDetails
                                                        ?.paymentLink
                                                        .amountRequired
                                                        ? 6
                                                        : 12
                                                }
                                                xs={12}
                                            >
                                                <TextFieldComponent
                                                    required={
                                                        paymentLinkDetails
                                                            ?.paymentLink
                                                            .messageRequired
                                                    }
                                                    value={message}
                                                    onChange={(e: Event) => {
                                                        handlePersonalData(e)
                                                    }}
                                                    label={'Add a message'}
                                                    name={'message'}
                                                />
                                            </Grid>
                                            {paymentLinkDetails?.paymentLink
                                                .amountRequired && (
                                                <Grid item sm={6} xs={12}>
                                                    <TextFieldComponent
                                                        required={true}
                                                        value={amount}
                                                        onChange={(
                                                            e: Event
                                                        ) => {
                                                            handlePersonalData(
                                                                e
                                                            )
                                                        }}
                                                        label={'Amount'}
                                                        name={'amount'}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Box
                                            mt={4}
                                            justifyContent="center"
                                            display="flex"
                                        >
                                            <FormButton
                                                style={{ fontSize: '14px' }}
                                                width={
                                                    isScreenSmall
                                                        ? '310px'
                                                        : '20vw'
                                                }
                                                height={54}
                                                borderColor={WHITE}
                                                titleColor={WHITE}
                                                hoverTitleColor={BLACK_EIGHT}
                                                hoverBorderColor={BLACK_EIGHT}
                                                backgroundColor={BLACK_EIGHT}
                                                hoverBackgroundColor={WHITE}
                                                borderTopRightRadius={15}
                                                borderTopLeftRadius={15}
                                                borderBottomLeftRadius={15}
                                                borderBottomRightRadius={15}
                                                title={'Continue'}
                                                onClickHandler={
                                                    handleSwitchPaymentMethod
                                                }
                                                disabled={false}
                                                overRideButtonCheck={true}
                                            />
                                        </Box>
                                    </Box>
                                )}

                                {isSwitchExternalPaymentForm && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                fontSize: isScreenSmall
                                                    ? '16px'
                                                    : '1.04vw',
                                                fontWeight: 900,
                                            }}
                                        >
                                            Payment To
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            mt="7px"
                                            sx={{
                                                fontSize: isScreenSmall
                                                    ? '16px'
                                                    : '1.04vw',
                                            }}
                                        >
                                            {
                                                paymentLinkDetails?.paymentLink
                                                    .agent.agency.name
                                            }
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            mt={isScreenSmall ? '20px' : '40px'}
                                            color="gray"
                                            sx={{ fontSize: '16px' }}
                                        >
                                            Amount
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            mt="4px"
                                            fontWeight={900}
                                            sx={{ fontSize: '27px' }}
                                        >
                                            {paymentLinkDetails?.paymentLink
                                                .amountRequired
                                                ? 'Enter the amount on the next page'
                                                : 'AED ' +
                                                  (paymentLinkDetails?.invoice
                                                      ?.amount ??
                                                      paymentLinkDetails
                                                          ?.paymentLink
                                                          .amount ??
                                                      0)}
                                        </Typography>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            flexDirection={
                                                isScreenSmall
                                                    ? 'column-reverse'
                                                    : 'column'
                                            }
                                        >
                                            <Box mt="51px">
                                                {paymentMegthodsIconData.map(
                                                    ({ alt, icon }) => {
                                                        return (
                                                            <img
                                                                style={{
                                                                    width: '20px',
                                                                }}
                                                                src={icon}
                                                                alt={alt}
                                                                loading="lazy"
                                                            />
                                                        )
                                                    }
                                                )}
                                            </Box>

                                            <Box mt={4}>
                                                <FormButton
                                                    style={{ fontSize: '14px' }}
                                                    width={
                                                        isScreenSmall
                                                            ? '310px'
                                                            : '20vw'
                                                    }
                                                    height={54}
                                                    borderColor={WHITE}
                                                    titleColor={WHITE}
                                                    hoverTitleColor={
                                                        BLACK_EIGHT
                                                    }
                                                    hoverBorderColor={
                                                        BLACK_EIGHT
                                                    }
                                                    backgroundColor={
                                                        BLACK_EIGHT
                                                    }
                                                    hoverBackgroundColor={WHITE}
                                                    borderTopRightRadius={15}
                                                    borderTopLeftRadius={15}
                                                    borderBottomLeftRadius={15}
                                                    borderBottomRightRadius={15}
                                                    title={'Continue'}
                                                    onClickHandler={
                                                        handleContinue
                                                    }
                                                    disabled={false}
                                                    overRideButtonCheck={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )}

                                {isExternalPaymentMethodContainer && (
                                    <>
                                        {isScreenSmall && (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                width="100%"
                                                flexDirection="column"
                                            >
                                                <Typography
                                                    variant="h2"
                                                    style={{
                                                        fontSize: 16,
                                                        marginBottom: '40px',
                                                    }}
                                                    color={BLACK_EIGHT}
                                                >
                                                    Select Payment Method
                                                </Typography>
                                                <Lottie
                                                    animationData={paymentIcon}
                                                    style={{
                                                        width: 200,
                                                        marginTop: '20px',
                                                        marginBottom: '20px',
                                                    }}
                                                    loop={true}
                                                />
                                            </Box>
                                        )}
                                        <Box maxWidth="600px" mx="auto">
                                            <Box
                                                display="flex"
                                                flexDirection={{
                                                    xs: 'column',
                                                    sm: 'column',
                                                }}
                                                flexWrap="wrap"
                                                justifyContent="space-around"
                                            >
                                                <Box
                                                    mt={2}
                                                    justifyContent="center"
                                                    display="flex"
                                                >
                                                    <LightTooltip title="Coming Soon!">
                                                        <Button
                                                            sx={{
                                                                width: isScreenSmall
                                                                    ? '310px'
                                                                    : '14vw',
                                                                height: 54,
                                                                backgroundColor:
                                                                    'black',
                                                                borderTopRightRadius: 5,
                                                                borderTopLeftRadius: 5,
                                                                borderBottomLeftRadius: 5,
                                                                borderBottomRightRadius: 5,
                                                                color: WHITE,
                                                                fontSize:
                                                                    '14px !important',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        BLACK_EIGHT,
                                                                },
                                                            }}
                                                            onClick={
                                                                handleTooltipOpen
                                                            }
                                                        >
                                                            Pay with{' '}
                                                            <img
                                                                src={
                                                                    applePayIcon
                                                                }
                                                            />
                                                        </Button>
                                                    </LightTooltip>
                                                </Box>
                                                {/*
                    <Box mt={2} justifyContent="center" display="flex">
                        <FormButton
                            width={'14vw'}
                            height={45}
                            borderColor={WHITE}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBorderColor={BLACK_EIGHT}
                            backgroundColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            borderTopRightRadius={5}
                            borderTopLeftRadius={5}
                            borderBottomLeftRadius={5}
                            borderBottomRightRadius={5}
                            title={'Pay'}
                            startIcon={
                                <img
                                    src={googlePayIcon}
                                    width="30px"
                                    alt={'google-pay-icon'}
                                />
                            }
                            onClickHandler={() => {
                                return
                            }}
                            disabled={false}
                            overRideButtonCheck={true}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        />
                    </Box>

                    <Box mt={2} justifyContent="center" display="flex">
                        <FormButton
                            width={'14vw'}
                            height={45}
                            borderColor={WHITE}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBorderColor={BLACK_EIGHT}
                            backgroundColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            borderTopRightRadius={5}
                            borderTopLeftRadius={5}
                            borderBottomLeftRadius={5}
                            borderBottomRightRadius={5}
                            startIcon={
                                <img
                                    src={visaMasterIcon}
                                    width="30px"
                                    alt={'visa-master-icon'}
                                />
                            }
                            title={'Credit/Debit Card'}
                            onClickHandler={() => {
                                return
                            }}
                            disabled={false}
                            overRideButtonCheck={true}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        />
                    </Box>

                    <Box mt={2} justifyContent="center" display="flex">
                        <FormButton
                            width={'14vw'}
                            height={45}
                            borderColor={WHITE}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBorderColor={BLACK_EIGHT}
                            backgroundColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            borderTopRightRadius={5}
                            borderTopLeftRadius={5}
                            borderBottomLeftRadius={5}
                            borderBottomRightRadius={5}
                            startIcon={
                                <img
                                    src={bankTransferIcon}
                                    width="30px"
                                    alt={'bank-transfer-icon'}
                                />
                            }
                            title={'Bank Transfer'}
                            onClickHandler={() => {
                                return
                            }}
                            disabled={false}
                            overRideButtonCheck={true}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        />
                    </Box>

                    <Box mt={2} justifyContent="center" display="flex">
                        <FormButton
                            width={'14vw'}
                            height={45}
                            borderColor={WHITE}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBorderColor={BLACK_EIGHT}
                            backgroundColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            borderTopRightRadius={5}
                            borderTopLeftRadius={5}
                            borderBottomLeftRadius={5}
                            borderBottomRightRadius={5}
                            startIcon={
                                <img
                                    src={payLaterIcon}
                                    width="30px"
                                    alt={'pay-later-icon'}
                                />
                            }
                            title={'Bank Now Pay Later'}
                            onClickHandler={() => {
                                return
                            }}
                            disabled={false}
                            overRideButtonCheck={true}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        />
                    </Box> */}
                                                <Box
                                                    mt={2}
                                                    justifyContent="center"
                                                    display="flex"
                                                >
                                                    <div>
                                                        <meta
                                                            httpEquiv="Content-Type"
                                                            content="text/html; charset=UTF-8"
                                                        />
                                                        <meta
                                                            httpEquiv="X-UA-Compatible"
                                                            content="IE=edge,chrome=1"
                                                        />
                                                        <form
                                                            ref={formRef}
                                                            className="form-horizontal"
                                                            id="avanthgarde_payment_form"
                                                            action={
                                                                encryptedData?.paymentUrl
                                                            }
                                                            method="POST"
                                                        >
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="me_id"
                                                                defaultValue={
                                                                    encryptedData?.me_id
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="txn_details"
                                                                defaultValue={
                                                                    encryptedData?.txn_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="pg_details"
                                                                defaultValue={
                                                                    encryptedData?.pg_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="cust_details"
                                                                defaultValue={
                                                                    encryptedData?.cust_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="bill_details"
                                                                defaultValue={
                                                                    encryptedData?.bill_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="ship_details"
                                                                defaultValue={
                                                                    encryptedData?.ship_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="item_details"
                                                                defaultValue={
                                                                    encryptedData?.item_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="card_details"
                                                                defaultValue={
                                                                    encryptedData?.card_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="other_details"
                                                                defaultValue={
                                                                    encryptedData?.other_details
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                className="form-control"
                                                                name="upi_details"
                                                                defaultValue={
                                                                    encryptedData?.upi_details
                                                                }
                                                            />
                                                            <input
                                                                id={
                                                                    'submitSafeXPay'
                                                                }
                                                                hidden
                                                                type="submit"
                                                                name="submit"
                                                                defaultValue="Submit Transaction Request"
                                                            />
                                                        </form>
                                                    </div>

                                                    <FormButton
                                                        width={
                                                            isScreenSmall
                                                                ? '310px'
                                                                : '14vw'
                                                        }
                                                        height={54}
                                                        borderColor={WHITE}
                                                        titleColor={WHITE}
                                                        hoverTitleColor={
                                                            BLACK_EIGHT
                                                        }
                                                        hoverBorderColor={
                                                            BLACK_EIGHT
                                                        }
                                                        backgroundColor={
                                                            'black'
                                                        }
                                                        hoverBackgroundColor={
                                                            WHITE
                                                        }
                                                        borderTopRightRadius={5}
                                                        borderTopLeftRadius={5}
                                                        borderBottomLeftRadius={
                                                            5
                                                        }
                                                        borderBottomRightRadius={
                                                            5
                                                        }
                                                        noMargin={true}
                                                        startIcon={
                                                            <img
                                                                src={
                                                                    visaMasterIcon
                                                                }
                                                                width="30px"
                                                                alt={
                                                                    'visa-master-icon'
                                                                }
                                                            />
                                                        }
                                                        title={
                                                            'Credit/Debit Card'
                                                        }
                                                        onClickHandler={() =>
                                                            payWithSafeXPay()
                                                        }
                                                        disabled={false}
                                                        overRideButtonCheck={
                                                            true
                                                        }
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            fontSize: '14px',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box textAlign="center" mt={5}>
                                                {paymentMegthodsIconData.map(
                                                    ({ alt, icon }) => {
                                                        return (
                                                            <img
                                                                src={icon}
                                                                alt={alt}
                                                                loading="lazy"
                                                            />
                                                        )
                                                    }
                                                )}
                                            </Box>

                                            {/* <Box mt={4} justifyContent="center" display="flex">
                    <FormButton
                        width={'14vw'}
                        height={45}
                        borderColor={WHITE}
                        titleColor={WHITE}
                        hoverTitleColor={BLACK_EIGHT}
                        hoverBorderColor={BLACK_EIGHT}
                        backgroundColor={BLACK_EIGHT}
                        hoverBackgroundColor={WHITE}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        title={'Continue'}
                        // onClickHandler={handleShowSuccessMessage}
                        disabled={false}
                        overRideButtonCheck={true}
                    />
                </Box> */}
                                        </Box>
                                    </>
                                )}
                                {isSuccessMessageContainer && (
                                    <Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                        >
                                            <img
                                                src={paynestCollectLogo}
                                                alt="paynest-collect-logo"
                                                style={{
                                                    marginTop: isScreenSmall
                                                        ? 50
                                                        : 70,
                                                    marginBottom: isScreenSmall
                                                        ? 40
                                                        : 70,
                                                    width: isScreenSmall
                                                        ? '60px'
                                                        : '120px',
                                                }}
                                            />

                                            <Lottie
                                                animationData={doneIcon}
                                                className="done-icon"
                                                loop={true}
                                            />
                                            <Typography
                                                variant="h2"
                                                mt="10px"
                                                color="green"
                                            >
                                                Successfully!
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                mt="4px"
                                                mb="0px"
                                            >
                                                Payment Done Successfully
                                            </Typography>
                                        </Box>

                                        <Box
                                            mt={4}
                                            justifyContent="center"
                                            display="flex"
                                        >
                                            <FormButton
                                                width={
                                                    isScreenSmall
                                                        ? '310px'
                                                        : '14vw'
                                                }
                                                height={54}
                                                borderColor={WHITE}
                                                titleColor={WHITE}
                                                hoverTitleColor={BLACK_EIGHT}
                                                hoverBorderColor={BLACK_EIGHT}
                                                backgroundColor={BLACK_EIGHT}
                                                hoverBackgroundColor={WHITE}
                                                borderTopRightRadius={15}
                                                borderTopLeftRadius={15}
                                                borderBottomLeftRadius={15}
                                                borderBottomRightRadius={15}
                                                title={'Ok'}
                                                onClickHandler={() => {
                                                    return
                                                }}
                                                disabled={false}
                                                overRideButtonCheck={true}
                                                style={{ fontSize: '14px' }}
                                            />
                                        </Box>
                                    </Box>
                                )}
                            </>
                        </>
                    ) : (
                        <>
                            <Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <img
                                        src={paynestCollectLogo}
                                        alt="paynest-collect-logo"
                                        style={{
                                            marginTop: isScreenSmall ? 50 : 70,
                                            marginBottom: isScreenSmall
                                                ? 40
                                                : 70,
                                            width: isScreenSmall
                                                ? '60px'
                                                : '120px',
                                        }}
                                    />

                                    <Lottie
                                        animationData={failedIcon}
                                        className="done-icon"
                                        loop={true}
                                    />
                                    <Typography
                                        variant="h2"
                                        mt="10px"
                                        color="black"
                                    >
                                        Link Expired
                                    </Typography>
                                    <Typography variant="h6" mt="4px" mb="0px">
                                        Your Link Is Expired. Please Check Again
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    )
}
