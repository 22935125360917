import { styled } from '@mui/system'
import backgroundImage from '../../assets/background-video.gif'

export const AuthContainer = styled('div')({
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: 'white',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundSize: 'cover',
})

export const FormContainer = styled('div')({
    borderRadius: '25px',
    backgroundColor: 'white',
    minHeight: '580px',
    minWidth: '500px',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    margin: '2rem 4rem 0rem 4rem',
})

export const CompanyAuthLogo = styled('div')({
    margin: '2rem 4rem 0rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
})

export const LoginFormGroup = styled('div')({
    margin: '2rem 4rem 0rem 4rem',
})

export const LoginFormInput = styled('div')({
    paddingTop: '10px',
    marginBottom: '10px',
})
