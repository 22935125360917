// packages block
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@mui/material'
// common block
import { TableHeader } from './tableHeader'
import { AddUserDialog } from '../userForm'
import { PreAddDialog } from '../../common/preAddDialog'
import { FileUploader } from '../../common/fileUploader'
import { OnSuccessDialog } from '../../common/onSuccessDialog'
import { AddMultiUserDialog } from '../userForm/addMultiUserDialog'
import { ManagementTable } from '../../../components/managementTable'
// constants, theme, utils and api block
import '../style.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import AuthState from '../../../interfacesTypes/auth'
import UserManagementServices from '../../../apis/agency/user-management'
import debounce from 'lodash/debounce'

const debug = Boolean(process.env.REACT_APP_DEBUG)

export const UsersTableContainer = () => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const [isPreAddDialogVisible, setIsPreAddDialogVisible] = useState(false)
    const [isAddUserDialogVisible, setIsAddUserDialogVisible] = useState(false)
    const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false)
    const [isFileUploaderVisible, setIsFileUploaderVisible] = useState(false)
    const [isAddMultiUserDialogVisible, setIsAddMultiUserDialogVisible] =
        useState(false)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(null)
    const [selectedIds, setSelectedIds] = useState([])
    const [startEntry, setStartEntry] = useState(1)
    const [endEntry, setEndEntry] = useState(10)
    const [users, setUsers] = useState([])

    const [searchString, setSearchString] = useState('')
    const { t } = useTranslation()
    // eslint-disable-next-line
    let limit = 10
    const USER_MANAGEMENT_COLUMNS = [
        {
            id: 'reraNum',
            numeric: false,
            disablePadding: true,
            label: `RERA No.`,
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: t('text_name'),
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            label: t('text_email'),
        },
        {
            id: 'phoneNumber',
            numeric: false,
            disablePadding: true,
            label: t('text_phone_number'),
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: true,
            label: t('text_role'),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: t('text_status'),
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: true,
            label: 'Actions',
        },
    ]

    const openPreAddDialog = () => setIsPreAddDialogVisible(true)
    const closePreAddDialog = () => setIsPreAddDialogVisible(false)

    const openAddUserDialog = () => {
        closePreAddDialog()
        setIsAddUserDialogVisible(true)
    }

    const openAddMultiUserDialog = () => {
        closePreAddDialog()
        setIsAddMultiUserDialogVisible(true)
    }

    const closeAddUserDialog = () => {
        setIsAddUserDialogVisible(false)
        setIsPreAddDialogVisible(true)
    }

    const closeAddMultipleUserDialog = () => {
        setIsAddMultiUserDialogVisible(false)
        setIsPreAddDialogVisible(true)
    }

    const goHome = () => {
        setIsAddUserDialogVisible(false)
        setIsPreAddDialogVisible(false)
        setIsSuccessDialogVisible(false)
        window.location.reload()
    }

    const handlePage = (pageNumber: number) => setPage(pageNumber)

    const handleSelection = (ids: any) => {
        setSelectedIds(ids)
    }

    const debounceSearchString = useCallback(debounce(setSearchString, 400), [])
    console.log('auth', auth)

    const fetchUsers = async (auth: AuthState) => {
        if (auth.user && pathname.startsWith('/agency')) {
            UserManagementServices.getAllUsersForAgency(
                auth.user.agencyId,
                page,
                limit,
                auth?.token
            ).then((response: any) => {
                console.log(response)

                setUsers(response?.data?.rows)
                setTotalCount(response?.data.count)
                setStartEntry(page === 0 ? 1 : page * limit)
                setEndEntry(
                    (page + 1) * limit > response?.data.count
                        ? response.data.count
                        : (page + 1) * limit
                )
            })

            setLoading(false)
        }
    }

    const successCallback = async () => {
        closeAddUserDialog()
        setIsSuccessDialogVisible(true)
    }

    const multipleUserSuccessCallback = async () => {
        closeAddMultipleUserDialog()
        setIsSuccessDialogVisible(true)
    }

    const handleFileUpload = async () => {
        closePreAddDialog()
        setIsFileUploaderVisible(true)
    }

    useEffect(() => {
        if (searchString === '') {
            setLoading(true)
            fetchUsers(auth).catch((error) => console.error(error))
        } else {
            console.log()
            ;(async () => {
                setLoading(true)
                try {
                    if (auth.user && pathname.startsWith('/agency')) {
                        await UserManagementServices.fetchUsersForAgency(
                            auth.user.agencyId,
                            page,
                            limit,
                            searchString,
                            auth?.token
                        ).then((response: any) => {
                            console.log(response)

                            setUsers(response?.data?.rows)
                            setTotalCount(response?.data.count)
                            setStartEntry(page === 0 ? 1 : page * limit)
                            setEndEntry(
                                (page + 1) * limit > response?.data.count
                                    ? response.data.count
                                    : (page + 1) * limit
                            )
                        })

                        setLoading(false)
                    }
                } catch (e) {
                    setLoading(false)
                }
            })()
        }
        // eslint-disable-next-line
    }, [searchString, page])

    const handleSearching = (value: any) => {
        setPage(0)
        setTotalCount(null)
        debounceSearchString(value)
    }

    return (
        <div className="user-management-page">
            <Typography
                variant="h2"
                fontSize={'1.15vw'}
                style={{
                    fontFamily: 'Montserrat',
                    marginBottom: 20,
                }}
            >
                {t('text_user_management')}
            </Typography>

            <div className="user-management-container">
                <TableHeader
                    handleSearching={handleSearching}
                    selectedIds={selectedIds}
                    handleAdd={openPreAddDialog}
                />

                {loading ? (
                    <div className={'user-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        {t('text_fetching_user_data')}
                    </div>
                ) : (
                    <ManagementTable
                        handleSelection={handleSelection}
                        pageNumber={page}
                        handlePage={handlePage}
                        totalCount={totalCount}
                        tableFor="userManagement"
                        data={users}
                        columns={USER_MANAGEMENT_COLUMNS}
                        startEntry={startEntry}
                        endEntry={endEntry}
                    />
                )}
            </div>

            <PreAddDialog
                title={t('text_add_user')}
                singleTitle={'Single'}
                multipleTitle={'Bulk'}
                handleClose={closePreAddDialog}
                open={isPreAddDialogVisible}
                addUser={openAddUserDialog}
                addMultiUser={openAddMultiUserDialog}
                addButtonTitle={''}
                backbut={true}
            />

            {isAddMultiUserDialogVisible && (
                <AddMultiUserDialog
                    title={'Bulk Upload'}
                    multipleTitle={'Select File'}
                    handleClose={closeAddMultipleUserDialog}
                    open={isAddMultiUserDialogVisible}
                    successCallback={multipleUserSuccessCallback}
                    addButtonTitle={'Upload'}
                    handleFileUpload={handleFileUpload}
                    backbut={true}
                />
            )}

            {isAddUserDialogVisible && (
                <AddUserDialog
                    title={t('text_add_user')}
                    subHeading="Official Details"
                    handleClose={closeAddUserDialog}
                    open={isAddUserDialogVisible}
                    successCallback={successCallback}
                    showCircularProgressBar={true}
                    isEditUser={false}
                />
            )}

            {isSuccessDialogVisible && (
                <OnSuccessDialog
                    handleClose={closeAddUserDialog}
                    open={isSuccessDialogVisible}
                    goHome={goHome}
                    successMessage={'Added User Successfully'}
                />
            )}

            <FileUploader open={isFileUploaderVisible} loading={loading} />
        </div>
    )
}
