export const english = {
    text_dashboard: 'Dashboard',
    text_students: 'Students',
    text_student_management: 'Student Management',
    text_balance_management: 'Balance Management',
    text_user_management: 'User Management',
    text_school_management: 'School Management',
    text_recent_transactions: 'Recent Transactions',
    text_settings: 'Settings',
    text_logout: 'Logout',
    text_english: 'English',
    text_arabic: 'عربي',
    text_school_fee_management_dashboard: 'School Fee Management Dashboard',
    text_welcome_back: 'Welcome Back!',
    text_fetching_data: 'Fetching Data',
    text_notifications: 'Notifications',
    text_quick_stats: 'Quick Stats',
    text_total_school_fees_received_vs_pending:
        'Total School Fees Received vs Pending',
    text_select_period: 'Select period',
    text_today: 'Today',
    text_last_week: 'Last Week',
    text_last_month: 'Last Month',
    text_last_6_months: 'Last 6 months',
    text_no_pending_receiving_fee_currently:
        'No Pending Receiving fee Currently',
    text_received: 'Received',
    text_AED: 'AED',
    text_pending: 'Pending',
    text_school_fees_received_vs_pending: 'School Fees Received vs Pending',
    text_fetching_details: 'Fetching Details',
    text_fetching_transactions: 'Fetching Transactions',
    text_get_help: 'Get help',
    text_online_users: 'Online Users',
    text_fetching_user: 'Fetching User',
    text_no_user_is_online: 'No User is Online',
    text_fetching_student_data: 'Fetching Student data',
    text_add_student: 'Add Student',
    text_add_single_student: 'Add Single Student',
    text_add_multiple_students: 'Add Multiple Students',
    text_student_create: 'studentCreate',
    text_student: 'student',
    text_adding_multiple_students: 'Adding Multiple Students',
    text_delete_students: 'Delete Students',
    text_school: 'school',
    text_user: 'user',
    text_delete_user: 'Delete User',
    text_this_will_delete_the_school_do_you_still_want_to_proceed:
        'This will delete the school. Do you still want to proceed?',
    text_this_will_delete_the_use_do_you_want_to_proceed:
        'This will delete the user. Do you want to proceed?',
    "text_this_will_delete_all_student's_data_including_student's_invoices_details_and_payment_history_do_you_still_want_to_proceed":
        "This will delete all student's data including student's invoices details and payment history.Do you still want to proceed?",
    text_cancel: 'Cancel',
    text_confirm: 'Confirm',
    text_fetching_and_copying_data_to_database:
        'Fetching and copying data to database',
    text_step_one: 'Step 1',
    text_step_two: 'Step 2',
    text_successfully_updated: 'Successfully Updated!',
    text_successfully_created: 'Successfully Created!',
    text_successfully: 'Successfully!',
    text_changes_saved_successfully: 'Changes saved successfully',
    text_ok: 'ok',
    text_continue: 'Continue',
    text_first_name: 'First Name',
    text_last_name: 'Last Name',
    text_date_of_birth: 'Date of birth',
    text_gender: 'Gender',
    text_emirates_id_number: 'Emirates ID',
    text_nationality: 'Nationality',
    text_religion: 'Religion',
    text_parent_passport_number: 'Parent Passport Number',
    text_address_and_contact_detail: 'Address and contact detail',
    text_area: 'Area',
    text_address: 'Address',
    text_phone_number: 'Phone Number',
    text_other_number: 'Other Number',
    text_email_address: 'Email address',
    "text_add_scanned_image_of_student's_uae_id_card":
        "Add Scanned Image of Student's UAE ID Card",
    'text_drag_&_drop': 'Drag & Drop',
    text_your_files_or: 'your files or',
    text_browse: 'Browse',
    text_grade_details: 'Grade Details',
    text_student_Id: 'Student ID',
    text_date_of_admission: 'Date of Admission',
    text_grade: 'Grade',
    text_section: 'Section',
    text_personal_detail: 'Personal Details',
    "text_parent's_detail": 'Guardian Details',
    text_update_balance: 'Update Balance',
    text_add_balance: 'Add Balance',
    text_upload: 'Upload',
    text_choose_file: 'Choose File',
    text_download_our_predefined_template: 'download our predefined template',
    "text_If_you_don't_have_an_excel_file_to_store_student_data_you_can":
        "If you don't have an excel file to store student data, you can",
    text_delete: 'Delete',
    text_fetching_balances: 'Fetching Balances',
    text_balance: 'balance',
    text_update_single_student_balance: 'Update Single Student Balance',
    text_add_single_invoice: 'Add Single Invoice',
    text_update_bulk_student_balance: 'Update Bulk Student Balance',
    text_add_multiple_invoices: 'Add Multiple Invoices',
    text_add_invoice: 'Add Invoice',
    text_notifyParent: 'notifyParent',
    text_notify_parents: 'Notify Parents',
    text_checking_students_data_this_may_take_some_time:
        'Checking Students Data, this may take some time!',
    text_name: 'Name',
    text_send_notification: 'Send Notification',
    text_view: 'View',
    text_edit_student: 'Edit Student',
    text_edit_balance: 'Edit Balance',
    text_edit_user: 'Edit User',
    text_search_by_agent_name: 'Search by Agent Name',
    text_update_bulk: 'Update Bulk',
    text_contact: 'Contact',
    text_title: 'Title',
    text_description: 'Description',
    text_created_at: 'Created At',
    text_nofifications: 'Nofifications',
    text_fetching_notifications: 'Fetching Notifications',
    text_total_balance_amount: 'Total Balance Amount (AED)',
    text_due_date: 'Due Date',
    text_status: 'Status',
    text_access: 'Access',
    text_transaction_id: 'Transaction ID',
    text_ref_no: 'Ref No',
    text_amount: 'Amount',
    text_date: 'Date',
    text_search_students_or_transactions_id:
        'Search students or transactions ID',
    text_fetching_users: 'Fetching Users',
    text_add_user: 'Add User',
    text_male: 'Male',
    text_female: 'Female',
    text_other: 'Other',
    text_emirates_id: 'Emirates ID',
    text_passport: 'Passport',
    text_region: 'Region',
    text_email: 'Email',
    text_password: 'Password',
    text_official_details: 'Official Details',
    text_school_id: 'School ID',
    text_role: 'Role',
    text_employee_id: 'Employee ID',
    text_designation: 'Designation',
    text_add_scanned_image_of_User_uae_id_card:
        "Add Scanned Image of User's UAE ID Card",
    text_search_by_user_name: 'Search by user name',
    text_search_transaction_id: 'Search Transaction ID',
    text_fetching_user_info: 'Fetching User Info',
    text_account_setting: 'Account Setting',
    text_change_password: 'Change Password',
    text_edit_profile: 'Edit Profile',
    text_save_changes: 'Save Changes',
    text_reset_changes: 'Reset Changes',
    text_current_password: 'Current Password',
    text_new_password: 'New Password',
    text_confirm_new_password: 'Confirm New Password',
    text_records_not_found: 'Records not found',
    text_error_in_searching_transactions: 'Error in searching transactions',
    text_something_went_wrong_try_again_later:
        'Something went wrong ,try again later',
    text_error_in_deleting_student_please_try_again:
        'Error in deleting Student. Please try again',
    text_error_in_filter_by_student: 'Error in filter by student',
    text_no_unique_student_found: 'No unique student found',
    text_students_added_successfully: 'Students added successfully',
    text_error_in_multiple_file_uploading: 'Error in multiple file uploading',
    text_error_in_searching_students: 'Error in searching students',
    text_student_deleted_successfully: 'Student deleted Successfully',
    text_your_csv_must_be_10MB_or_greater_than_10MB_try_again:
        'Your CSV must be 10MB or greater than 10MB, Try again',
    text_balance_updated_successfully: 'Balance Updated Successfully',
    text_error_in_updating_student_please_try_again:
        'Error in updating student. Please try again',
    text_error_in_adding_student_please_try_again:
        'Error in adding student. Please try again',
    text_student_updated_successfully: 'Student Updated Successfully',
    text_error_in_fetching_student_detail: 'Error in fetching student detail',
    text_image_size_is_greater_than_2MB_kindly_use_2MB_for_profile_image:
        'Image size is greater than 2MB, Kindly use 2MB for profile image',
    text_password_updated_successfully: 'Password Updated Successfully',
    text_reset_changes_successfully: 'Reset Changes Successfully!',
    text_transaction_deleted_successfully: 'Transaction deleted successfully',
    text_error_in_deleting_transactions_please_try_again:
        'Error in deleting Transactions. Please try again',
    text_class: 'Class',
    text_total_payable_fee: 'Total Payable Fee',
    text_invoice_id: 'Invoice ID',
    text_Fee_month: 'Fee Month',
    text_student_name: 'Student Name',
    text_recent_transaction_detail: 'Recent Transaction Detail',
    text_error_in_searching_users: 'Error in searching users',
    text_error_in_deleting_user_please_try_again:
        'Error in deleting user. Please try again',
    text_user_deleted_successfully: 'User Deleted Successfully',
    text_error_in_adding_user_please_try_again:
        'Error in adding user. Please try again',
    text_error_in_searching_schools: 'Error in searching Schools',
    text_error_in_fetching_user_detail: 'Error in fetching user detail',
    text_sign_in: 'Sign In',
    text_sign_up: 'Sign Up',
    text_please_enter_your_registered_email_id_and_password:
        'Please Enter Your Registered Email ID and Password',
    text_forgot_password: 'Forgot Password?',
    text_i_accept_terms_and_conditions: 'I accept Terms and Conditions',
    text_forgot_password_simple: 'Forgot Password',
    text_please_enter_your_registered_email_id_To_reset_password:
        'Please Enter Your Registered Email ID To Reset Password',
    text_please_enter_verification_code_from_your_phone_or_email:
        'Please enter verification code from your phone or email',
    text_resend_otp: 'Resend Otp',
    text_please_enter_new_password: 'Please Set Your New Password',
    text_search_by_school_id_or_school_name:
        'Search by School Id or School Name',
    text_add_school: 'Add School',
    text_edit: 'Edit',
    text_school_deleted_successfully: 'School Deleted Successfully',
    text_error_in_fetching_school_detail: 'Error in fetching school detail',
    text_error_in_deleting_school_please_try_again:
        'Error in deleting school. Please try again',
    text_school_details: 'School Details',
    text_edit_school: 'Edit School',
    text_delete_school: 'Delete School',
    text_merchant_id_number: 'Merchant ID Number',
    text_school_added_successfully: 'School Added Successfully',
    text_error_in_adding_school_please_try_again:
        'Error in adding school. Please try again',
    text_school_updated_successfully: 'School Updated Successfully',
    text_error_in_updating_school_please_try_again:
        'Error in updating school. Please try again',
    text_school_name: 'School Name',
    text_school_address: 'School Address',
    text_merchant_id: 'Merchant ID',
    text_vat: 'VAT',
    text_paynest_fee: 'Paynest Fee',
    text_fetching_schools_data: 'Fetching schools data',
    text_parent: 'parent',
    text_payee_type: 'Payee Type',
    text_payee_id: 'Payee ID',
    text_is_required: 'is required',
    text_please_wait_for_a_moment: 'Please wait for a moment',
    text_bank_management: 'Account Management',
    text_bank_added_successfully: 'Bank Added Succesfully',
    text_error_in_adding_bank_please_try_again:
        'Error in adding bank. Please try again',
    text_bank_updated_successfully: 'Bank updated sunccessfully',
    text_error_in_updating_bank_please_try_again:
        'Error in updating bank. Please try again',
    text_bank_details: 'Bank Details',
    text_bank_deleted_successfully: 'Bank deleted successfully',
    text_error_in_deleting_bank_please_try_again:
        'Error in deleting bank. Please try again',
    text_error_in_fetching_bank_detail: 'Error in fetching bank details',
    text_edit_bank: 'Edit bank',
    text_delete_bank: 'Delete bank',
    text_search_by_bank_id_or_bank_name: 'Search by bank id or bank name',
    text_add_bank: 'Add Bank',
    text_error_in_searching_banks: 'Error in searching bank',
    text_fetching_banks_data: 'Fetching banks data',
    text_bank: 'Bank',
    text_bank_name: 'Bank Name',
    text_bank_swift_code: 'Bank Swift Code',
    text_bank_address: 'Bank Address',
    text_account_number: 'Account Number',
    text_account_title: 'Account Title',
    text_account_iban: 'Account IBAN',
    text_this_will_delete_the_account_do_you_want_to_proceed:
        'This will delete the account. Do you want to proceed?',
    text_balances: 'Balances',
    text_users: 'Users',
    text_accounts: 'Accounts',
    text_transactions: 'Transactions',
    text_schools: 'Schools',
}
