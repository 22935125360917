import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

// Theme
import { BLACK_EIGHT, ORANGE_TWO, WHITE } from '../../../theme'

// Assets
import backButtonIcon from '../../../assets/images/back-button-icon.svg'

// Interfaces/Types
import { Agent } from '../../../interfacesTypes/agent'

// Services/APIs
import AgentService from '../../../apis/agent/users'

// Redux
import { RootState } from '../../../redux/store'

// Components
import { FormButton } from '../../common/button'
import { OnSuccessDialog } from '../../common/onSuccessDialog'
import { WidgetList } from '../../Stats'
import { TransactionsTableContainer } from '../../transactionTableContainer'
import { AddUserDialog } from '../userForm'
import UserManagement from '../../../apis/agency/user-management'
import Alert from '../../common/Alert'

interface RouteParams {
    userId: string
}
export const UserDetailContainer = () => {
    const { auth } = useSelector((state: RootState) => state)
    const [isEditUser, setIsEditUser] = useState(false)
    const [isDeleteUser, setIsDeleteUser] = useState(false)
    const { userId } = useParams<RouteParams>()

    const [agentData, setAgentData] = useState<Agent | null>(null)

    const fullName = `${agentData?.firstName} ${agentData?.lastName}`

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    const handleBack = () => {
        window.location.href = '/agency/users'
    }

    const handleDeleteUser = async () => {
        if (agentData?.id) {
            await UserManagement.deleteAgent(agentData?.id, auth?.token).then(
                (res) => {
                    if (res.status) {
                        setIsDeleteUser(true)
                    } else {
                        Alert.error(res?.statusMsg)
                    }
                }
            )
        }
    }

    const handleEditUser = () => {
        setIsEditUser(true)
    }

    const closeAddUserDialog = () => {
        setIsEditUser(false)
    }

    const successCallback = async () => {
        closeAddUserDialog()
        setIsDeleteUser(false)
    }

    const goHome = () => {
        setIsEditUser(false)
        setIsDeleteUser(false)
        window.location.href = '/agency/users'
    }

    useEffect(() => {
        if (userId) {
            AgentService.getAgentByAgentId(userId, auth?.token).then((res) => {
                if (res.status) {
                    setAgentData(res.data)
                }
            })
        }
    }, [])
    return (
        <Box>
            <Box display="flex" alignItems="center">
                <Button
                    variant="contained"
                    onClick={handleBack}
                    style={{
                        backgroundColor: 'transparent',
                        maxWidth: '30px',
                        maxHeight: '30px',
                        minWidth: '30px',
                        minHeight: '30px',
                        borderRadius: '3px',
                        boxShadow: 'none',
                    }}
                >
                    <img
                        src={backButtonIcon}
                        className="backbut"
                        style={{ width: 48, cursor: 'pointer' }}
                        alt="back-button-icon"
                    />
                </Button>

                <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                    Agent Details
                </Typography>
                <Box
                    display="flex"
                    maxWidth="85%"
                    width="100%"
                    justifyContent="flex-end"
                >
                    <FormButton
                        width={'10vw'}
                        height={45}
                        borderColor={WHITE}
                        titleColor={WHITE}
                        hoverTitleColor={ORANGE_TWO}
                        hoverBorderColor={ORANGE_TWO}
                        backgroundColor={ORANGE_TWO}
                        hoverBackgroundColor={WHITE}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        title={'Delete User'}
                        onClickHandler={handleDeleteUser}
                        disabled={false}
                        overRideButtonCheck={true}
                    />
                    <FormButton
                        width={'10vw'}
                        height={45}
                        borderColor={WHITE}
                        titleColor={WHITE}
                        hoverTitleColor={BLACK_EIGHT}
                        hoverBorderColor={BLACK_EIGHT}
                        backgroundColor={BLACK_EIGHT}
                        hoverBackgroundColor={WHITE}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        title={'Edit User'}
                        onClickHandler={handleEditUser}
                        disabled={false}
                        overRideButtonCheck={true}
                    />
                </Box>
            </Box>

            <Box
                display="flex"
                padding="30px"
                justifyContent="space-around"
                flexWrap="wrap"
                border="1px solid #b1b1b0"
                borderRadius={'20px'}
                mt={4}
            >
                <Box
                    style={{
                        borderRight: '2px solid #b1b1b0',
                    }}
                    pr={10}
                >
                    <Typography color="#5bbc61">Rera No.</Typography>

                    <Typography fontWeight={800}>
                        {agentData ? agentData.reraNo : '---'}
                    </Typography>
                </Box>
                <Box
                    style={{
                        borderRight: '2px solid #b1b1b0',
                    }}
                    pr={10}
                >
                    <Typography color="#5bbc61">Full Name</Typography>

                    <Typography fontWeight={800}>
                        {fullName ? fullName : '---'}
                    </Typography>
                </Box>
                <Box
                    style={{
                        borderRight: '2px solid #b1b1b0',
                    }}
                    pr={10}
                >
                    <Typography color="#5bbc61">Phone Number</Typography>

                    <Typography fontWeight={800}>
                        {agentData?.phoneNumber
                            ? `${agentData?.countryCode} ${agentData?.phoneNumber}`
                            : '---'}
                    </Typography>
                </Box>
                <Box
                    style={{
                        borderRight: '2px solid #b1b1b0',
                    }}
                    pr={10}
                >
                    <Typography color="#5bbc61">Email</Typography>

                    <Typography fontWeight={800}>
                        {agentData?.email ? agentData.email : '---'}
                    </Typography>
                </Box>
                <Box
                    style={{
                        borderRight: '0px solid #b1b1b0',
                    }}
                    pr={10}
                >
                    <Typography color="#5bbc61">Nationality</Typography>

                    <Typography fontWeight={800}>
                        {agentData?.nationality
                            ? capitalizeFirstLetter(agentData?.nationality)
                            : '---'}
                    </Typography>
                </Box>
            </Box>
            <WidgetList
                id={userId}
                type={'userManagement'}
                loggedIn={auth.authenticated}
            />
            <TransactionsTableContainer
                isAgentTransactions={true}
                id={userId}
            />

            {isEditUser && (
                <AddUserDialog
                    title={'Edit User Details'}
                    subHeading="Official Details"
                    handleClose={closeAddUserDialog}
                    open={isEditUser}
                    successCallback={successCallback}
                    showCircularProgressBar={true}
                    isEditUser={true}
                    agentId={agentData?.id}
                    agentData={agentData}
                />
            )}

            {isDeleteUser && (
                <OnSuccessDialog
                    handleClose={closeAddUserDialog}
                    open={isDeleteUser}
                    goHome={goHome}
                    successMessage={'Delete User Successfully'}
                />
            )}
        </Box>
    )
}
