import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import './index.css'
// Theme

// Assets
import backButtonIcon from '../../../assets/images/back-button-icon.svg'

// Services/APIs
import AgencyTransactions from '../../../apis/agency/transactions'

// Redux
import { RootState } from '../../../redux/store'
import { dateFormat } from '../../../constants'
import QRCode from 'qrcode'

// Components

interface RouteParams {
    transactionId: string
}
export const TransactionDetailContainer = () => {
    const { auth } = useSelector((state: RootState) => state)
    const { transactionId } = useParams<RouteParams>()
    const [payeeInfoName, setPayeeInfoName] = useState<any>(null)
    const [payeeInfoEmail, setPayeeInfoEmail] = useState<any>(null)
    const [payeeInfoPhoneNumber, setPayeeInfoPhoneNumber] = useState<any>(null)
    const [invoiceId, setInvoiceId] = useState<any>(null)
    const [invoiceAmount, setInvoiceAmount] = useState<any>(null)
    const [dateOfIssue, setDateOfIssue] = useState<any>(null)
    const [qrUrl, setQrUrl] = useState<any>(null)
    const [finalUrl, setfinalUrl] = useState<any>(null)
    const handleBack = () => {
        window.location.href = '/agency/transactions'
    }
    let completeQrUrl
    let finalQrCode
    useEffect(() => {
        if (transactionId) {
            AgencyTransactions.getTransactionDetailByTransactionId(
                transactionId,
                auth?.token
            )
                .then(async (response: any) => {
                    if (response?.status) {
                        setPayeeInfoName(
                            `${response?.data?.data?.payeeInfo?.firstName} ${response?.data?.data?.payeeInfo?.lastName} `
                        )
                        setPayeeInfoEmail(
                            response?.data?.data?.payeeInfo?.email
                        )
                        setPayeeInfoPhoneNumber(
                            `${response?.data?.data?.payeeInfo?.countryCode}${response?.data?.data?.payeeInfo?.phone} `
                        )
                        setInvoiceId(response?.data?.data?.paymentLink?.id)
                        setInvoiceAmount(response?.data?.data?.amount)
                        setDateOfIssue(response?.data?.data?.createdAt)
                        setQrUrl(response?.data?.data?.paymentLink?.url)
                        completeQrUrl = `${process.env.REACT_APP_FE_URL}/agent/payment-link/${qrUrl}`
                        finalQrCode = await QRCode.toDataURL(completeQrUrl)
                        setfinalUrl(finalQrCode)
                    }
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not get list of agents. Something went wrong.',
                        error: error,
                    }
                })
        }
    }, [transactionId])

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="left">
                <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                    Transaction Details
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleBack}
                    style={{
                        backgroundColor: 'transparent',
                        maxWidth: '30px',
                        maxHeight: '30px',
                        minWidth: '30px',
                        minHeight: '30px',
                        borderRadius: '3px',
                        boxShadow: 'none',
                        marginLeft: '30px',
                        marginTop: '30px',
                    }}
                >
                    <img
                        src={backButtonIcon}
                        className="backbut"
                        style={{ width: 48, cursor: 'pointer' }}
                        alt="back-button-icon"
                    />
                </Button>
            </Box>

            {/* <Box
                display="flex"
                padding="30px"
                justifyContent="space-around"
                flexDirection="column"
                border="1px solid #334050"
                borderRadius={'40px'}
                mt={4}
                maxWidth="726px"
                mx="auto"
            >
                <Box px={10} py={5} display="flex">

                </Box>
                <Box p={4} display="flex" flexDirection="column">
                    <Box>
                        <img src={paynestCollectLogo} alt="paynest-collect-logo" />
                        <Box>
                            <Typography
                                variant="h3"
                                style={{
                                    marginBottom: 10,
                                    marginLeft: 8,
                                    color: BLACK,
                                    fontWeight: 700,
                                    fontSize: '1.18vw',
                                }}
                            >
                                Invoice ID
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    marginLeft: 8,
                                    fontSize: '0.65vw',
                                    marginBottom: 10,
                                }}
                            >
                                EDS - 45234623565
                            </Typography>
                        </Box>
                    </Box>
                    <Box>

                    </Box>
                    <Divider />
                    <Box>
                        <Button>

                        </Button>
                    </Box>
                </Box>

            </Box> */}

            <div className="main-conatiner">
                <table width="80%" className="upper-table-container">
                    <tr>
                        <td width="50%">
                            <img
                                style={{ width: 70 }}
                                src="https://borderpay.blob.core.windows.net/public/paynest-collect-logo.png"
                            />
                        </td>
                        <td width="50%">
                            <div className="heading-text">Date of issue</div>
                            <div className="dynamic-text">
                                {dateFormat(dateOfIssue)}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td width="50%">
                            <div className="heading-text">Invoice ID</div>
                            <div className="dynamic-text">{invoiceId}</div>
                        </td>
                        <td width="50%">
                            <div className="heading-text">Bill to</div>
                            <div className="dynamic-text">{payeeInfoName}</div>
                            <div className="dynamic-text">{payeeInfoEmail}</div>
                            <div className="dynamic-text">
                                {payeeInfoPhoneNumber}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td width="50%" style={{ verticalAlign: 'top' }}>
                            <div className="heading-text">From</div>
                            <div className="dynamic-text">
                                {auth?.user?.agencyName}
                            </div>
                            <div className="dynamic-text">
                                {auth?.user?.email}
                            </div>

                            <div
                                style={{ marginTop: 70 }}
                                className="heading-text"
                            >
                                Invoice Amount
                            </div>
                            <div className="payment-text">
                                {invoiceAmount
                                    ? `AED ${invoiceAmount}.00`
                                    : 'N/A'}
                            </div>
                        </td>
                        <td width="50%">
                            <div className="scan-text">Scan To Pay</div>
                            <img style={{ width: '150px' }} src={finalUrl} />
                        </td>
                    </tr>
                </table>

                <table width="80%" className="lower-table">
                    <tr>
                        <td width="100%">
                            <div className="lower-table-header-container">
                                <span className="description-text">
                                    Description
                                </span>

                                <span className="amount-text">Amount</span>

                                <span className="total-text">Total</span>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td width="100%">
                            <div
                                style={{
                                    width: 'inherit',
                                    borderRadius: '16px',
                                    padding: '10px',
                                }}
                            >
                                <span className="description-dynamic-text">
                                    Services
                                </span>

                                <span className="amount-dynamic-text">
                                    {invoiceAmount
                                        ? `AED ${invoiceAmount}.00`
                                        : 'N/A'}
                                </span>

                                <span className="total-dynamic-text">
                                    {invoiceAmount
                                        ? `AED ${invoiceAmount}.00`
                                        : 'N/A'}
                                </span>
                            </div>
                            <div
                                style={{
                                    height: '1px',
                                    backgroundColor: '#707070',
                                }}
                            ></div>
                        </td>
                    </tr>

                    <tr>
                        <td align="right">
                            <div className="button-container">
                                <span className="button-text">Total</span>

                                <span className="button-dynamic-text">
                                    {invoiceAmount
                                        ? `AED ${invoiceAmount}.00`
                                        : 'N/A'}
                                </span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </Box>
    )
}
