import axios from 'axios'
import { LIST_AGENTS_URL, UPDATE_AGENT_STATUS_URL } from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgentService = {
    listAgentsByAgencyId: async (
        agencyId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${LIST_AGENTS_URL}?agencyId=${agencyId}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get agent list. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAgentByAgentId: async (
        agentId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${LIST_AGENTS_URL}?agentId=${agentId}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get agent. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    updateAgentStatus: async (
        agentId: string | number,
        status: 'ACTIVE' | 'INACTIVE',
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.put(
                `${UPDATE_AGENT_STATUS_URL}?agentId=${agentId}`,
                { status: status },
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not update agent status. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgentService
