// packages block
import { useTranslation } from 'react-i18next'
import { Dialog, Typography } from '@mui/material'
// common block
import '../users/style.css'
import { FormButton } from './button'
import { preaddStyles } from '../../styles/preAddStyles'
// constants, theme, utils and api block
import { BLACK_EIGHT, ORANGE_TWO, WHITE } from '../../theme'

export const ConfirmationDeleteDialog = ({
    open,
    close,
    handleDelete,
    loading,
    title,
}: any) => {
    const { t } = useTranslation()
    const classes = preaddStyles()

    return (
        <Dialog
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className="confirmation_card">
                <Typography
                    variant="h2"
                    style={{ textAlign: 'center', marginBottom: 50 }}
                >
                    {title === t('text_school') && t('text_delete_school')}
                    {title === t('text_student') && t('text_delete_students')}
                    {title === t('text_user') && t('text_delete_user')}
                    {title === t('text_bank') && t('text_delete_bank')}
                    {title === t('text_account') && 'Delete Bank'}
                </Typography>
                <Typography
                    variant="h6"
                    mb={4}
                    style={{ textAlign: 'center', color: 'black' }}
                >
                    {title === t('text_school') &&
                        t(
                            'text_this_will_delete_the_school_do_you_still_want_to_proceed'
                        )}
                    {title === t('text_user') &&
                        t(
                            'text_this_will_delete_the_use_do_you_want_to_proceed'
                        )}
                    {title === t('text_student') &&
                        t(
                            "text_this_will_delete_all_student's_data_including_student's_invoices_details_and_payment_history_do_you_still_want_to_proceed"
                        )}
                    {title === t('text_bank') &&
                        t(
                            'text_this_will_delete_the_account_do_you_want_to_proceed'
                        )}

                    {title === t('text_account') &&
                        'This Will Delete All The Bank Data History. Do You Still Want To Proceed?'}
                </Typography>

                <div className="confirmation_buttons">
                    <div className="btn1">
                        <FormButton
                            title={t('text_cancel')}
                            backgroundColor={ORANGE_TWO}
                            borderColor={ORANGE_TWO}
                            titleColor={WHITE}
                            hoverBackgroundColor={WHITE}
                            hoverBorderColor={ORANGE_TWO}
                            hoverTitleColor={ORANGE_TWO}
                            height={45}
                            width={'10vw'}
                            borderTopRightRadius={15}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            onClickHandler={close}
                        />
                    </div>

                    <div className="btn1">
                        <FormButton
                            height={45}
                            width={'10vw'}
                            borderTopRightRadius={15}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            backgroundColor={BLACK_EIGHT}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            hoverBorderColor={BLACK_EIGHT}
                            title={t('text_continue')}
                            loading={loading}
                            loadingColor={'#F8966A'}
                            onClickHandler={handleDelete}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
