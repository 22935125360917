// packages block
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
} from '@mui/material'
// components block
// import Alert from "../common/Alert";
import LoginController from '../login/LoginController'
// history, context, constants, and utils block
// import history from "../common/history";
import {
    EMAIL,
    VERIFY_OTP_AGENCY_ROUTE,
    VERIFY_OTP_AGENT_ROUTE,
} from '../../constants'
import { LoginUserInput } from '../../interfacesTypes'
import {
    AuthContainer,
    CompanyAuthLogo,
    FormContainer,
    LoginFormGroup,
    LoginFormInput,
} from '../login/login.style'
import { BLACK, BLACK_EIGHT, GREY_SEVENTEEN, WHITE } from '../../theme'
import { emailValidationSchema } from '../../validationSchemas'
import { useHistory } from 'react-router'
import { FormButton } from '../common/button'
import Alert from '../common/Alert'
import AgencyAuth from '../../apis/agency/auth'
import { useDispatch } from 'react-redux'
import { authActions } from '../../redux/store/slices/auth'
import AgentAuth from '../../apis/agent/auth'
import backButtonIcon from '../../assets/images/back-button-icon.svg'
import paynestCollectLogo from '../../assets/images/paynest-collect-login-logo.png'

const debug = Boolean(process.env.REACT_APP_DEBUG)

const ForgotPasswordComponent = (): JSX.Element => {
    const dispatch = useDispatch()

    const [checked, setChecked] = useState(true)
    const [isArabic, setIsArabic] = useState(false)
    const [isEnglish, setIsEnglish] = useState(true)
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginUserInput>({
        mode: 'all',
        resolver: yupResolver(emailValidationSchema),
        defaultValues: {
            email: '',
        },
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }
    const validateInput: SubmitHandler<LoginUserInput> = (data) => {
        if (data.email === '') {
            const err = {
                status: false,
                statusMsg: 'Email cannot be empty',
            }
            if (debug) console.error(err)
            return err
        }

        return {
            status: true,
            statusMsg: 'Validated fields',
        }
    }

    const onSubmit: SubmitHandler<LoginUserInput> = async (data) => {
        const validate = validateInput(data) as {
            status: boolean
            statusMsg: string
        }
        if (!validate.status) {
            Alert.error(validate.statusMsg)
            return validate
        }
        const pathname = window.location.pathname

        let forgetPasswordResponse

        localStorage.setItem('otpEmail', data.email)

        if (pathname === '/agency/forgot-password') {
            forgetPasswordResponse = await AgencyAuth.forgotPassword(data)
            if (forgetPasswordResponse.status) {
                Alert.success(`OTP sent to ${data.email}`)
                dispatch(
                    authActions.updateOtpEmail({
                        email: data.email,
                    })
                )
                return history.push(VERIFY_OTP_AGENCY_ROUTE)
            }
        }
        if (pathname === '/agent/forgot-password') {
            forgetPasswordResponse = await AgentAuth.forgotPassword(data)
            if (forgetPasswordResponse.status) {
                Alert.success(`OTP sent to ${data.email}`)
                dispatch(
                    authActions.updateOtpEmail({
                        email: data.email,
                    })
                )
                return history.push(VERIFY_OTP_AGENT_ROUTE)
            }
        }
        Alert.info(forgetPasswordResponse.statusMsg)
        return forgetPasswordResponse
    }

    // useEffect(() => {}, [])

    const { email: { message: emailError } = {} } = errors

    const changeLanguage = (lng: string) => {
        if (lng === 'en') {
            setIsEnglish(true)
            setIsArabic(false)
        } else {
            setIsArabic(true)
            setIsEnglish(false)
        }

        i18n.changeLanguage(lng)
    }

    const handleBack = () => {
        window.location.href = '/agency/login'
    }

    return (
        <>
            <AuthContainer>
                <FormContainer>
                    <CompanyAuthLogo>
                        <Button
                            variant="contained"
                            onClick={handleBack}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                                position: 'absolute',
                                left: '0px',
                                bottom: '0px',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>
                        <img
                            src={paynestCollectLogo}
                            alt="paynest-collect-logo"
                        />
                    </CompanyAuthLogo>

                    <LoginFormGroup>
                        <Box>
                            <Typography
                                variant="h3"
                                style={{
                                    marginBottom: 10,
                                    marginLeft: 8,
                                    color: BLACK,
                                    fontWeight: 700,
                                    fontSize: '1.18vw',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {t('text_forgot_password')}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    marginLeft: 8,
                                    fontSize: '0.65vw',
                                    marginBottom: 10,
                                    fontWeight: 500,
                                }}
                            >
                                {t(
                                    'text_please_enter_your_registered_email_id_To_reset_password'
                                )}
                            </Typography>
                        </Box>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <LoginFormInput>
                                <LoginController
                                    control={control}
                                    controllerName="email"
                                    controllerLabel={EMAIL}
                                    fieldType="email"
                                    error={emailError}
                                />
                            </LoginFormInput>

                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <FormControlLabel
                                    sx={{
                                        '& span': {
                                            fontSize: '0.65vw',
                                            color: '#74ba6b',
                                        },
                                    }}
                                    control={
                                        <Checkbox
                                            color="success"
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={t(
                                        'text_i_accept_terms_and_conditions'
                                    )}
                                />
                            </Box>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: 10,
                                }}
                            >
                                <FormButton
                                    noMargin={true}
                                    type="submit"
                                    height={50}
                                    width={'100%'}
                                    borderColor={BLACK_EIGHT}
                                    title={t('text_continue')}
                                    backgroundColor={BLACK_EIGHT}
                                    titleColor={WHITE}
                                    hoverTitleColor={WHITE}
                                    hoverBackgroundColor={BLACK_EIGHT}
                                    loading={false}
                                    borderTopLeftRadius={15}
                                    borderTopRightRadius={15}
                                    borderBottomRightRadius={15}
                                    borderBottomLeftRadius={15}
                                    disabled={!checked}
                                    style={{ fontSize: '0.9vw' }}
                                />
                            </div>

                            <Box
                                display="flex"
                                justifyContent="center"
                                mt={4}
                                mb={2}
                            >
                                <Typography
                                    variant="h3"
                                    style={{
                                        color: isEnglish
                                            ? BLACK
                                            : GREY_SEVENTEEN,
                                        cursor: 'pointer',
                                        borderRight: `2px solid ${BLACK}`,
                                        paddingRight: 5,
                                    }}
                                    onClick={() => changeLanguage('en')}
                                >
                                    {t('text_english')}
                                </Typography>

                                <Typography
                                    variant="h3"
                                    style={{
                                        color: isArabic
                                            ? BLACK
                                            : GREY_SEVENTEEN,
                                        cursor: 'pointer',
                                        marginLeft: 5,
                                    }}
                                    onClick={() => changeLanguage('ar')}
                                >
                                    {t('text_arabic')}
                                </Typography>
                            </Box>
                        </form>
                    </LoginFormGroup>
                </FormContainer>
            </AuthContainer>
        </>
    )
}

export default ForgotPasswordComponent
