// packages block
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@mui/material'
// common block
import { ManagementTable } from '../../../components/managementTable'
// constants, theme, utils and api block
import '../style.css'
import { TableHeader } from './tableHeader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import AgentTransactions from '../../../apis/agent/transactions'
import AgencyTransactions from '../../../apis/agency/transactions'

const debug = Boolean(process.env.REACT_APP_DEBUG)

export const TransactionTable = () => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const [invoices, setInvoices] = useState([])
    const [paymentLinks, setPaymentLinks] = useState([])
    const [loading, setLoading] = useState(true)
    const [isInvoices, setIsInvoices] = useState(true)
    const [isPaymentLinks, setIsPaymentLinks] = useState(false)

    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(null)
    const [startEntry, setStartEntry] = useState(1)
    const [endEntry, setEndEntry] = useState(10)
    const [selectedIds, setSelectedIds] = useState([])

    const { t } = useTranslation()
    // eslint-disable-next-line
    let limit = 10

    const INVOICES_COLUMNS = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: t('text_name'),
        },
        {
            id: 'invoice',
            numeric: false,
            disablePadding: true,
            label: 'Invoice',
        },
        {
            id: 'amount',
            numeric: false,
            disablePadding: true,
            label: t('text_amount'),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: t('text_status'),
        },
    ]

    const PAYMENT_LINKS_COLUMNS = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: t('text_name'),
        },
        {
            id: 'invoice',
            numeric: false,
            disablePadding: true,
            label: 'Invoice',
        },
        {
            id: 'amount',
            numeric: false,
            disablePadding: true,
            label: t('text_amount'),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: t('text_status'),
        },
    ]

    const handlePage = (pageNumber: number) => setPage(pageNumber)

    const handleSelection = (ids: any) => {
        setSelectedIds(ids)
    }

    const handleInvoices = () => {
        setPage(0)
        setIsInvoices(true)
        setIsPaymentLinks(false)
    }

    const handlePaymentLinks = () => {
        setPage(0)
        setIsPaymentLinks(true)
        setIsInvoices(false)
    }
    useEffect(() => {
        if (auth.user && pathname.startsWith('/agency')) {
            if (isPaymentLinks) {
                setLoading(true)
                AgencyTransactions.getPaymentLinkTransactionsByAgency(
                    auth?.user?.agencyId,
                    page,
                    limit,
                    auth?.token
                )
                    .then((response: any) => {
                        console.log(response, 'no response')
                        if (response.status) {
                            setPaymentLinks(response?.data?.rows)
                            setTotalCount(response?.data.count)
                            setStartEntry(page === 0 ? 1 : page * limit)
                            setEndEntry(
                                (page + 1) * limit > response?.data.count
                                    ? response.data.count
                                    : (page + 1) * limit
                            )
                        }
                    })
                    .catch((error) => {
                        const err = {
                            status: false,
                            statusMsg:
                                'Could not get list of transactions. Something went wrong.',
                            error: error,
                        }
                        if (debug) console.log(err)
                    })
                setLoading(false)
            } else if (isInvoices) {
                setLoading(true)
                AgencyTransactions.getInvoiceTransactionsByAgency(
                    auth?.user?.agencyId,
                    page,
                    limit,
                    auth?.token
                )
                    .then((response: any) => {
                        if (response?.status) {
                            setInvoices(response?.data?.rows)
                            setTotalCount(response?.data.count)
                            setStartEntry(page === 0 ? 1 : page * limit)
                            setEndEntry(
                                (page + 1) * limit > response?.data.count
                                    ? response.data.count
                                    : (page + 1) * limit
                            )
                        }
                    })
                    .catch((error) => {
                        const err = {
                            status: false,
                            statusMsg:
                                'Could not get list of transactions. Something went wrong.',
                            error: error,
                        }
                        if (debug) console.log(err)
                    })
                setLoading(false)
            }
        } else if (auth?.user && pathname?.startsWith('/agent')) {
            if (isPaymentLinks) {
                setLoading(true)
                AgentTransactions.getPaymentLinkTransactionsByAgent(
                    auth?.user?.id,
                    page,
                    limit,
                    auth?.token
                )
                    .then((response: any) => {
                        if (response?.status) {
                            setPaymentLinks(response?.data?.rows)
                            setTotalCount(response?.data.count)
                            setStartEntry(page === 0 ? 1 : page * limit)
                            setEndEntry(
                                (page + 1) * limit > response?.data.count
                                    ? response.data.count
                                    : (page + 1) * limit
                            )
                        }
                    })
                    .catch((error) => {
                        const err = {
                            status: false,
                            statusMsg:
                                'Could not get list of transactions. Something went wrong.',
                            error: error,
                        }
                        if (debug) console.log(err)
                    })
                setLoading(false)
            } else if (isInvoices) {
                setLoading(true)
                AgentTransactions.getInvoiceTransactionsByAgent(
                    auth?.user?.id,
                    page,
                    limit,
                    auth?.token
                )
                    .then((response: any) => {
                        if (response?.status) {
                            setInvoices(response?.data?.rows)
                            setTotalCount(response?.data.count)
                            setStartEntry(page === 0 ? 1 : page * limit)
                            setEndEntry(
                                (page + 1) * limit > response?.data.count
                                    ? response.data.count
                                    : (page + 1) * limit
                            )
                        }
                    })

                    .catch((error) => {
                        const err = {
                            status: false,
                            statusMsg:
                                'Could not get list of transactions. Something went wrong.',
                            error: error,
                        }
                        if (debug) console.log(err)
                    })
                setLoading(false)
            }
        }
    }, [page, isPaymentLinks, isInvoices])
    return (
        <div className="transactions-management-page">
            <Typography
                variant="h2"
                fontSize={'1.15vw'}
                style={{
                    fontFamily: 'Montserrat',
                    marginBottom: 20,
                }}
            >
                {'Transaction History'}
            </Typography>

            <div>
                <TableHeader
                    isPaymentLinks={isPaymentLinks}
                    isInvoices={isInvoices}
                    handleInvoices={handleInvoices}
                    handlePaymentLinks={handlePaymentLinks}
                />

                {loading ? (
                    <div className={'transactions-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        {'Fetching Data...'}
                    </div>
                ) : (
                    <ManagementTable
                        handleSelection={handleSelection}
                        pageNumber={page}
                        handlePage={handlePage}
                        totalCount={totalCount}
                        tableFor={
                            isInvoices
                                ? 'invoiceTransactions'
                                : 'paymentLinkTransactions'
                        }
                        data={isInvoices ? invoices : paymentLinks}
                        columns={
                            isInvoices
                                ? INVOICES_COLUMNS
                                : PAYMENT_LINKS_COLUMNS
                        }
                        startEntry={startEntry}
                        endEntry={endEntry}
                    />
                )}
            </div>
        </div>
    )
}
