import React from 'react'
import ReactPaginate from 'react-paginate'
import './footer.css'
import { Typography } from '@mui/material'
export const Footer = ({
    total,
    handlePage,
    page,
    startEntry,
    endEntry,
    tableFor,
}: any) => {
    const handlePageClick = (data: { selected: any }) => {
        handlePage(data.selected)
    }

    return (
        <div className="table-footer">
            <Typography>
                Showing {startEntry} to {endEntry} of {total} entries
            </Typography>
            <ReactPaginate
                forcePage={page}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(total / 10)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
                previousClassName="previous-button"
                nextLinkClassName="next-button"
                pageLinkClassName={'page-link'}
                activeLinkClassName="active-page-link"
            />
        </div>
    )
}
