import axios from 'axios'
import {
    CREATE_STANDALONE_PAYMENT_LINK_URL,
    GET_AGENT_PAYMENT_LINKS_URL,
    GET_AGENT_PAYMENT_LINKS_WITH_UUID_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgentPaymentLinks = {
    getAgentPaymentLinks: async (
        agentId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_AGENT_PAYMENT_LINKS_URL}?agentId=${agentId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get payment links. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAgentPaymentLinkWithUuid: async (uuid: string, token: string | null) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_AGENT_PAYMENT_LINKS_WITH_UUID_URL}?uuid=${uuid}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get payment links. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    createStandalonePaymentLink: async (
        agentId: string | number,
        sendReceipt: boolean,
        messageRequired: boolean,
        payeeInfoRequired: boolean,
        amountRequired: boolean,
        amount: string,
        description: string,
        token: string | null
    ) => {
        try {
            const paymentLinkObj = {
                sendReceipt: sendReceipt,
                messageRequired: messageRequired,
                payeeInfoRequired: payeeInfoRequired,
                amountRequired: amountRequired,
                amount: amount,
                description: description.length ? description : '',
            }
            console.log(paymentLinkObj, 'check payment link obj')
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.post(
                `${CREATE_STANDALONE_PAYMENT_LINK_URL}?agentId=${agentId}`,
                paymentLinkObj,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not create payment link. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgentPaymentLinks
