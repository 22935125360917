// packages block
import Lottie from 'lottie-react'
import { useTranslation } from 'react-i18next'
import { Dialog, Typography, Box } from '@mui/material'
// common block
import '../users/style.css'
import { FormButton } from './button'
// constants, theme, utils and api block
import { BLACK_EIGHT, WHITE } from '../../theme'
import { preaddStyles } from '../../styles/preAddStyles'
import addSuccessIconAnimation from '../../assets/confirm-icon-animation.json'

export const OnSuccessDialog = ({ open, goHome }: any) => {
    const classes = preaddStyles()
    const { t } = useTranslation()

    return (
        <Dialog
            style={{ maxWidth: '400px', margin: 'auto' }}
            className={classes.root}
            // onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className="success_card">
                <Lottie
                    animationData={addSuccessIconAnimation}
                    style={{ width: 150 }}
                    loop={true}
                />

                <Typography variant="h2">{t('text_successfully')}</Typography>

                <Box marginTop={2} display="flex" justifyContent="center">
                    <FormButton
                        title={t('text_ok')}
                        onClickHandler={goHome}
                        height={45}
                        width={'10vw'}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        backgroundColor={BLACK_EIGHT}
                        titleColor={WHITE}
                        hoverTitleColor={BLACK_EIGHT}
                        borderColor={BLACK_EIGHT}
                        hoverBackgroundColor={WHITE}
                        hoverBorderColor={BLACK_EIGHT}
                    />
                </Box>
            </div>
        </Dialog>
    )
}
