// packages block
import { Dialog, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
// common block
import { FormButton } from '../../common/button'
import { PreDialogHeader } from '../../common/preDialogHeader'
// constants, theme, utils and api block
import '../style.css'
import { preaddStyles } from '../../../styles/preAddStyles'
import { BLACK_EIGHT, ORANGE_TWO, WHITE } from '../../../theme'
import { dummyBankDetailData } from '../../../constants'

export const ViewAccountDialog = ({
    bankData,
    open,
    handleClose,
    title,
    showCircularProgressBar,
    successCallback,
    bankName,
    bankSwiftCode,
    bankAddress,
    accountNumber,
    accountTitle,
    accountIban,
}: any) => {
    const classes = preaddStyles()
    const [step] = useState(1)
    const [loading] = useState(false)

    const { t } = useTranslation()

    const handleSubmission = async () => {
        successCallback()
    }

    return (
        <Dialog
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className={classes.mainContainer}>
                <PreDialogHeader
                    title={title}
                    handleClose={handleClose}
                    showCircularProgressBar={showCircularProgressBar}
                    backbut={true}
                />

                <div
                    className={classes.dropContainer}
                    style={{ margin: '0px auto' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRight: '0px solid darkgray',
                                // '2px solid darkgray',
                                width: 'calc(90%/3)',
                                textAlign: 'center',
                                marginBottom: 20,
                                marginTop: 20,
                            }}
                        >
                            <Typography variant="h6" mb={2}>
                                Bank Name
                            </Typography>

                            <Typography variant="body2">
                                {bankName.length
                                    ? bankName
                                    : bankData?.bank?.name ?? '---'}
                            </Typography>
                        </div>
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRight: '0px solid darkgray',
                                // '2px solid darkgray',
                                width: 'calc(90%/3)',
                                textAlign: 'center',
                                marginBottom: 20,
                                marginTop: 20,
                            }}
                        >
                            <Typography variant="h6" mb={2}>
                                Bank Swift Code
                            </Typography>

                            <Typography variant="body2">
                                {bankSwiftCode.length
                                    ? bankSwiftCode
                                    : bankData?.swiftCode ?? '---'}
                            </Typography>
                        </div>
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRight: '0px solid darkgray',
                                // '2px solid darkgray',
                                width: 'calc(90%/3)',
                                textAlign: 'center',
                                marginBottom: 20,
                                marginTop: 20,
                            }}
                        >
                            <Typography variant="h6" mb={2}>
                                Bank Address
                            </Typography>

                            <Typography variant="body2">
                                {bankAddress.length
                                    ? bankAddress
                                    : bankData?.bank?.address ?? '---'}
                            </Typography>
                        </div>
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRight: '0px solid darkgray',
                                // '2px solid darkgray',
                                width: 'calc(90%/3)',
                                textAlign: 'center',
                                marginBottom: 20,
                                marginTop: 20,
                            }}
                        >
                            <Typography variant="h6" mb={2}>
                                Account Number
                            </Typography>

                            <Typography variant="body2">
                                {accountNumber.length
                                    ? accountNumber
                                    : bankData?.accountNumber ?? '---'}
                            </Typography>
                        </div>
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRight: '0px solid darkgray',
                                // '2px solid darkgray',
                                width: 'calc(90%/3)',
                                textAlign: 'center',
                                marginBottom: 20,
                                marginTop: 20,
                            }}
                        >
                            <Typography variant="h6" mb={2}>
                                Account Title
                            </Typography>

                            <Typography variant="body2">
                                {accountTitle.length
                                    ? accountTitle
                                    : bankData?.accountTitle?.toUpperCase() ??
                                      '---'}
                            </Typography>
                        </div>
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRight: '0px solid darkgray',
                                // '2px solid darkgray',
                                width: 'calc(90%/3)',
                                textAlign: 'center',
                                marginBottom: 20,
                                marginTop: 20,
                            }}
                        >
                            <Typography variant="h6" mb={2}>
                                Account IBAN
                            </Typography>

                            <Typography variant="body2">
                                {accountIban.length
                                    ? accountIban
                                    : bankData?.iban ?? '---'}
                            </Typography>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                height: 1,
                                backgroundColor: 'darkgray',
                            }}
                        />
                    </div>
                </div>

                <div className={classes.addSchooldialogFooter}>
                    <div className={classes.footerButton}>
                        <FormButton
                            title={t('text_confirm')}
                            onClickHandler={handleSubmission}
                            height={45}
                            width={'10vw'}
                            borderTopRightRadius={15}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            backgroundColor={BLACK_EIGHT}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            hoverBorderColor={BLACK_EIGHT}
                            step={step}
                            loading={loading}
                        />
                    </div>

                    <div className={classes.footerButton}>
                        <FormButton
                            title={t('text_cancel')}
                            height={45}
                            width={'10vw'}
                            borderTopRightRadius={15}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            backgroundColor={ORANGE_TWO}
                            borderColor={ORANGE_TWO}
                            titleColor={WHITE}
                            hoverBackgroundColor={WHITE}
                            hoverBorderColor={ORANGE_TWO}
                            hoverTitleColor={ORANGE_TWO}
                            onClickHandler={handleClose}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
