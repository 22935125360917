// packages block
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
// common block
import { TextFieldComponent } from '../../common/textFeild'
import '../style.css'
import { preaddStyles } from '../../../styles/preAddStyles'
// constants, theme, utils and api block

export const AccountForm = ({
    bankName,
    bankSwiftCode,
    bankAddress,
    accountNumber,
    accountTitle,
    accountIban,
    handlePersonalData,
    errors,
}: any) => {
    const classes = preaddStyles()
    const { t } = useTranslation()
    return (
        <div className={classes.addSchoolForm}>
            <div>
                <Grid container className={classes.gridRoot} spacing={4}>
                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('bankName') > -1 === true &&
                                'Bank Name' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('bankName') > -1}
                            value={bankName}
                            onChange={(e: Event) => handlePersonalData(e)}
                            label={'Bank Name'}
                            name={'bankName'}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('bankSwiftCode') > -1 === true &&
                                'Bank Swift Code' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('bankSwiftCode') > -1}
                            value={bankSwiftCode}
                            onChange={handlePersonalData}
                            label={'Bank Swift Code'}
                            name={'bankSwiftCode'}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('bankAddress') > -1 === true &&
                                'Bank Address' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('bankAddress') > -1}
                            type="text"
                            value={bankAddress}
                            onChange={handlePersonalData}
                            label={'Bank Address'}
                            name={'bankAddress'}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('accountNumber') > -1 === true &&
                                'Account Number' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('accountNumber') > -1}
                            value={accountNumber}
                            onChange={handlePersonalData}
                            label={'Account Number'}
                            name={'accountNumber'}
                            placeholder="paynest@gmail.com"
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('accountTitle') > -1 === true &&
                                'Account Title' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('accountTitle') > -1}
                            value={accountTitle}
                            onChange={handlePersonalData}
                            label={'Account Title'}
                            name={'accountTitle'}
                            placeholder="Account Title"
                            type="text"
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('accountIban') > -1 === true &&
                                'Account Iban' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('accountIban') > -1}
                            value={accountIban}
                            onChange={handlePersonalData}
                            label={'Account Iban'}
                            name={'accountIban'}
                            placeholder="Account Title"
                            type="text"
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
