import axios from 'axios'
import {
    FORGOT_PASSWORD_AGENCY_URL,
    RESET_PASSWORD_AGENCY_URL,
    SIGNIN_AGENCY_URL,
    SIGNUP_AGENCY_URL,
    VERIFY_OTP_AGENCY_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

const AgencyAuth = {
    signUp: async (data: any) => {
        try {
            const signUpObject = {
                agencyId: 1,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                password: data.password,
                phoneNumber: data.phoneNumber,
                countryCode: data.countryCode,
            }
            const response = await axios.post(SIGNUP_AGENCY_URL, signUpObject)

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not sign up agency user. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    signIn: async (data: any) => {
        try {
            const isEmail = validateEmail(data.email)
            const signInObject = {
                [isEmail ? 'email' : 'phoneNumber']: data.email,
                password: data.password,
            }
            const response = await axios.post(SIGNIN_AGENCY_URL, signInObject)

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not sign in agency user. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    forgotPassword: async (data: any) => {
        try {
            const forgetPasswordObj = {
                email: data.email,
            }
            const response = await axios.post(
                FORGOT_PASSWORD_AGENCY_URL,
                forgetPasswordObj
            )

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not create OTP for agency user. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    verifyOtp: async (data: any, otpEmail: string) => {
        try {
            const verifyOtp = {
                email: otpEmail,
                otp: data.verifyOtp,
            }
            const response = await axios.post(VERIFY_OTP_AGENCY_URL, verifyOtp)

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not verify OTP for agency user. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    resetPassword: async (data: any, agencyUserId: number) => {
        try {
            const resetPasswordObject = {
                agencyUserId: agencyUserId,
                newPassword: data.password,
            }
            const response = await axios.put(
                RESET_PASSWORD_AGENCY_URL,
                resetPasswordObject
            )

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not reset password for agency user. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgencyAuth
