// packages block
import { Dialog } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
// common block
import { AccountForm } from './accountForm'
import { FormButton } from '../../common/button'
import { PreDialogHeader } from '../../common/preDialogHeader'
// constants, theme, utils and api block
import '../style.css'
import { preaddStyles } from '../../../styles/preAddStyles'
import { BLACK_EIGHT, ORANGE_TWO, WHITE } from '../../../theme'
import AgencyBankServices from '../../../apis/agency/bank'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import bank from '../../../apis/agency/bank'
import Alert from '../../common/Alert'

export const AccountDialog = ({
    setBankFields,
    open,
    handleClose,
    title,
    isEditedItem,
    showCircularProgressBar,
    successCallback,
    setBankData,
    bankName,
    bankSwiftCode,
    bankAddress,
    accountNumber,
    accountTitle,
    accountIban,
}: any) => {
    const classes = preaddStyles()
    const [step] = useState(1)
    const [loading] = useState(false)

    const { t } = useTranslation()

    const [errors] = useState([])

    const handlePersonalData = (e: Event) => {
        if (typeof e === 'string' || e instanceof String) return
        const { name, value } = e.target as HTMLInputElement
        setBankFields((prevState: any) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleSubmission = async () => {
        if (!bankName.length) {
            Alert.error('Bank Name cannot be empty')
        }
        if (!bankSwiftCode.length) {
            Alert.error('Bank SwiftCode cannot be empty')
        }
        if (!bankAddress.length) {
            Alert.error('Bank Address cannot be empty')
        }
        if (!accountNumber.length) {
            Alert.error('Account Number cannot be empty')
        }
        if (!accountTitle.length) {
            Alert.error('Account Title cannot be empty')
        }
        if (!accountIban.length) {
            Alert.error('Account Iban cannot be empty')
        }
        if (
            bankName.length &&
            bankSwiftCode.length &&
            bankAddress.length &&
            accountNumber.length &&
            accountTitle.length &&
            accountIban.length
        ) {
            successCallback()
        }
    }
    return (
        <Dialog
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className={classes.mainContainer}>
                <PreDialogHeader
                    title={title}
                    handleClose={handleClose}
                    showCircularProgressBar={showCircularProgressBar}
                    backbut={true}
                />

                <div className={classes.dropContainer}>
                    <AccountForm
                        bankName={bankName}
                        bankSwiftCode={bankSwiftCode}
                        bankAddress={bankAddress}
                        accountNumber={accountNumber}
                        accountTitle={accountTitle}
                        accountIban={accountIban}
                        errors={errors}
                        handlePersonalData={handlePersonalData}
                    />
                </div>

                <div className={classes.addSchooldialogFooter}>
                    <div className={classes.footerButton}>
                        <FormButton
                            noMargin={true}
                            title={t('text_cancel')}
                            height={45}
                            width={'15vw'}
                            borderTopRightRadius={15}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            backgroundColor={ORANGE_TWO}
                            borderColor={ORANGE_TWO}
                            titleColor={WHITE}
                            hoverBackgroundColor={WHITE}
                            hoverBorderColor={ORANGE_TWO}
                            hoverTitleColor={ORANGE_TWO}
                            onClickHandler={handleClose}
                        />
                    </div>

                    <div className={classes.footerButton}>
                        <FormButton
                            title={t('text_continue')}
                            onClickHandler={handleSubmission}
                            height={45}
                            width={'15vw'}
                            borderTopRightRadius={15}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            backgroundColor={BLACK_EIGHT}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            hoverBorderColor={BLACK_EIGHT}
                            step={step}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
